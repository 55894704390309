<template>
    <div class="container container-custom">
        <div class="container-interior-dashboard">
            <div class="row">
                <Menu_Left />
                <div  class="col-sm">
                  <Agb />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Menu_Left from '@/components/Menu_Left'
  import Agb from '@/components/Footer/Agb'
  export default {
    components: {
      Menu_Left,
      Agb,
    },
  }
</script>