<template>
    <div class="container go-success-container">
        <br>
        <h1>Abholung beauftragen</h1>
        <br>
        <h4>Auftrag bestätigt</h4>
        <br>
        <p><strong>Vielen Dank für Ihren Auftrag</strong></p>
        <br>
        <div v-if="!isLoading">
            <div class="spinner-border" role="status"> 
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div v-if="isLoading">
            <p>Ihre Daten sind bei uns eingegangen. Die Abholung mit der GO! Auftragsnummer <strong>{{ url_data }}</strong> erfolgt am <strong>{{ praxis.date }}</strong> in der Zeit zwischen <strong>{{ praxis.fromTime }}</strong> und <strong>{{ praxis.toTime }}</strong> Uhr an der folgenden Adresse.</p>
        </div>
        <br>
        <p><strong>Adresse Abholung</strong></p>
        <br>
        <PickupAdress />
        <br>
        <br>
    </div>
</template>
<script>
import PickupAdress from '@/components/Menu/Scandaten/PickupAdress'
import axios from "axios";
export default {
    data() {
        return {
            isLoading: false,
            url_data: null,
            praxis: {
                fromTime: '',
                toTime  : '',
                date    : '',
            },
        }
    },
    components: {
        PickupAdress,
    },
    methods: {
        formatDateHuman(date){
            let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
            let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
            let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
            return `${day}.${month}.${year}`
        },
        async callPickGoData(hwb_param) {
            try {
                await axios
                .get('get-data-go', { 
                    params: {
                        hwb: hwb_param
                    }
                })
                .then((response) => {
                    this.isLoading          = true
                    this.praxis.fromTime    = response.data.body.timefrom
                    this.praxis.toTime      = response.data.body.timeto
                    var myDate              = new Date(response.data.body.date)
                    this.praxis.date        = this.formatDateHuman(new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate()))
                })
            } catch (e) {
                this.isLoading = false
                this.$swal({
                    icon: 'error',
                    title: 'Fehler',
                    text: 'Bitte versuchen Sie es später erneut',
                })
                
            }
        },
    },
    mounted() {
        this.$swal({
            icon : 'success',
            title : 'Erledigt',
            text : 'Abholung wurde beauftragt',
        })
        this.callPickGoData(this.url_data=this.$route.params.hwb)
    },
}
</script>
<style lang="scss"> 
    .go-success-container{
        height: 70vh;

        h1{
            font-size: 1.9rem;
            font-weight: bold;
        }

        h4{
            font-size: 1.4rem;
            font-weight: bold;
        }
    }
</style>