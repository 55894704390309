<template>
    <div class="container upload-success">
        <br>
        <h1>Scandaten versenden</h1>
        <br>
        <h2>Scandaten wurden versendet</h2>
        <br>
        <p><strong>Vielen Dank für die Zusendung</strong></p>
        <br>
        <p>Ihre Scandaten sind bei uns eingegangen. Bei Rückfragen wenden wir uns an folgenden Praxisstandort.</p>
        <br>
        <p><strong>Praxisstandort</strong></p>
        <br>
        <PickupAdress />
    </div>
</template>
<script>
import PickupAdress from '@/components/Menu/Scandaten/PickupAdress'
export default {
    components: {
        PickupAdress,
    },
    mounted(){
        this.$swal({
            icon : 'success',
            title : 'Erledigt',
            text : 'Upload erfolgreich!',
        })
    }
}
</script>
<style lang="scss"> 
    .upload-success{
        height: 70vh;

        h1{
            font-size: 2.1rem;
            font-weight: 100;
        }

        h2{
            font-size: 1.74rem;
            font-weight: 100;
        }
    }
</style>