<template>
    <div class="container container-custom">
        <div class="container-interior-dashboard">
            <div class="row">
                <Menu_Left />
                <div  class="col container-middle-custom">
                  <Calculator />
                </div>
                <div class="col-sm-3 menu-info-title">
                  <Menu_Right />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Menu_Left from '@/components/Menu_Left'
  import Calculator from '@/components/Menu/Calculator'
  import Menu_Right from '@/components/Menu_Right'
  export default {
    components: {
      Menu_Left,
      Calculator,
      Menu_Right
    },
  }
</script>