<template>
  <!-- The Modal -->
  <div id="myModal" class="modal">
    <!-- Modal content -->
    <div class="modal-content">
      <button v-on:click="closemodal"><span class="close">&times;</span></button>
      <div class="row">
        <div class="col" style="font-size: 19px; font-weight: bold">
          <img src="../../assets/images/iStock-492655340.jpg" width="500" height="300" class="d-inline-block align-text-top">
          <br><br>
          <p>Sie sind gefragt! Wir möchten wissen, ob Sie mit unserer Technik und unserem Service zufrieden sind. Wo lief es bestens, wo können wir etwas besser machen? Bitte nehmen Sie sich etwas Zeit, um die Fragen zu beantworten. Zum Dank für Ihren Aufwand verlosen wir unter allen Teilnehmern der Umfrage eine Apple Watch. Wer gewonnen hat, geben wir Ende November bekannt. Herzlichen Dank und viel Erfolg! </p>
        </div>
      </div><br><br>
      <div class="row">
        <div class="col">
          <button class="btn-umfrage">           
            <router-link :to="{ name: 'questionnaire' }"><p>Hier teilnehmen</p></router-link>
          </button>
        </div>
      </div><br>
    </div>
  </div>
  <!-- End Modal  -->
  <div class="container container-custom">
    <div class="container-interior">
      <div class="interior-con" style="padding-top: 25px;">
        <p class="interior-con-title">Gutschein von Amazon</p>
        <br>
        <div class="row mobil-bonus">
          <div class="col-3 logo-amazon align-self-center">
            <img src="@/assets/images/amazon.svg" alt="imexdental logo" width="80" height="30" class="logo-fluid-amazon">  
          </div>
          <div class="col-9 align-self-center bonus-bar">
            <div class="progress">          
              <div class="progress-bar progress-bar-striped progress-bar-animated progress-bonus" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="{ width: bonusWithPercentage  }">
              </div> 
            </div>
            <div class="bonus-text">
              <span>{{ sendBonusToViewMobil }}</span> 
            </div>
          </div>
        </div>
        <div class="row desktop-bonus">
          <div class="col-1 logo-amazon align-self-center">
            <img src="@/assets/images/amazon.svg" alt="imexdental logo" width="80" height="30" class="logo-fluid-amazon">  
          </div>
          <div class="col-11 align-self-center bonus-bar">
            <div class="progress">          
              <div class="progress-bar progress-bar-striped progress-bar-animated progress-bonus" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" :style="{ width: bonusWithPercentage  }">
              </div> 
            </div>
            <div class="bonus-text">
              <span>{{ sendBonusToViewDesktop }}</span> 
            </div>
          </div>
        </div>
      </div>
      <div class="interior-con">
        <p class="interior-con-title">Direkt loslegen</p>
        <br>
        <div class="row">
          <div class="col-sm buttons-dash">
            <router-link to="/abholungen/kostenvoranschlag">
              <div class="desktop-sign">
                <p><font-awesome-icon :icon="['fas', 'euro-sign'] " /> KVA erstellen</p>
              </div>
            </router-link>
            <router-link to="/abholungen/kostenvoranschlag">
              <div class="mobil-sign">
                <p><font-awesome-icon :icon="['fas', 'euro-sign'] " /> KVA</p>
              </div>
            </router-link>
          </div>
          <div class="col-sm buttons-dash"> 
            <router-link to="/abholungen/lieferzeiten">
            <div class="desktop-sign">
              <p><font-awesome-icon :icon="['fas', 'clock'] " /> Lieferzeiten berechnen</p>
            </div>
            </router-link>
            <router-link to="/abholungen/lieferzeiten">
            <div class="mobil-sign">
              <p><font-awesome-icon :icon="['fas', 'clock'] " /> Lieferzeiten</p>
            </div>
            </router-link>
          </div>
          <div class="col-sm buttons-dash">
            <router-link to="/abholungen/abholung">
            <div class="desktop-sign">
              <p><font-awesome-icon :icon="['fas', 'briefcase'] " /> Abholung vorbereiten</p>
            </div>
            </router-link>
            <router-link to="/abholungen/abholung">
            <div class="mobil-sign">
              <p><font-awesome-icon :icon="['fas', 'briefcase'] " /> Abholung</p>
            </div>
            </router-link>
          </div>
          <div class="col-sm buttons-dash">
            <router-link to="/abholungen/scandaten">
              <div class="desktop-sign">
                <p><font-awesome-icon :icon="['fas', 'cloud'] " /> Scandaten versenden</p>
              </div>
            </router-link>
            <router-link to="/abholungen/scandaten">
              <div class="mobil-sign">
                <p><font-awesome-icon :icon="['fas', 'cloud'] " /> Scandaten</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="interior-con" style="border-bottom: none;">
        <p class="interior-con-title">Nützliche Infos</p>
        <br>
        <div class="row">
          <div class="col-sm block-infos">
            <div class="block-infos-interior">
                <img src="@/assets/images/iTeleskop20-info-onlineportal-teaser.jpg  " class="bild-infos img-fluid">  
              <p class="image-subtitle">iTeleskop® 2.0 - Tragekomfort auf Goldniveau</p>
              <br>
              <div class="buttom-info">
                <router-link to="/info/1"><p>Lesen</p></router-link>
              </div>
            </div>
          </div>
          <div class="col-sm block-infos">
            <div class="block-infos-interior">
                <img src="@/assets/images/vw-go.5791107b1785d8bad6f9.jpg" class="bild-infos img-fluid">  
              <p class="image-subtitle">Go! Express für Ihre Abholungen</p>
              <br>
              <div class="buttom-info">
                <router-link to="/info/2"><p>Lesen</p></router-link>
              </div>
            </div>
          </div>
          <div class="col-sm block-infos">
            <div class="block-infos-interior">
                <img src="@/assets/images/schiene.8d12ba18ce878044f1a7.jpg" class="bild-infos img-fluid">  
              <p class="image-subtitle">iStraight Zahnkorrektur - 25 % Mitarbeiterrabatt auf schönere Zähne!</p>
              <br>
              <div class="buttom-info">
                <router-link to="/info/3"><p>Lesen</p></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
  export default {
    name: 'Dashboard', 
    computed: {
        ...mapGetters({
            getBonus: 'auth/user',
        }),
        bonusWithPercentage(){
          return (this.getBonus.bonusmail % 10) * 10 + '%'
        },
        sendBonusToViewDesktop () {
            return this.getBonus.bonusmail % 10 + ' / 10 Abholungen'
        },
        sendBonusToViewMobil () {
            return this.getBonus.bonusmail % 10 + ' / 10'
        },
    },
    methods: {
        loadModal() {
          if(this.getBonus.question2022 == false){
            document.getElementById("myModal").style.display = "block";
          }
        },
        closemodal(){
          document.getElementById("myModal").style.display = "none";
        }
    },
    // mounted(){
    //     this.loadModal()
    // }
  }
</script>
<style>
    .btn-umfrage{
      background-color: #004085;
      color: #fff;
      font-size: 18px;
      padding: 6px 40px;
      border-radius: 15px;
      font-weight: bold;
    }

    .btn-umfrage:hover{
      background-color: #fff;
      color: #004085;
      border: 0.5px solid #004085;
    }

    /* The close button */
    .closebtn {
      margin-left: 15px;
      color: white;
      font-weight: bold;
      float: right;
      font-size: 22px;
      line-height: 20px;
      cursor: pointer;
      transition: 0.3s;
    }

  .bonus-bar{
    position: relative;
  }

  .bonus-text{
    position: absolute;
    left: 40%;
    top: 25%;
    font-family: "NotoSans-Medium";
    color: #fff;
    font-weight: bold;
  }

  .progress-bar-striped{
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent) !important;
  }

  .progress-bar-animated {
      -webkit-animation: auto;
      animation: auto;
  }

  .progress-bar{
    background-color: #1c3e8e;
  }

  /* The Modal (background) */
  .modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  /* Modal Content */
  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 50px;
    border: 1px solid #888;
    width: 50%;
    height: auto
  }

  /* The Close Button */
  .close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }


  /* Desktop devices */
  @media (min-width: 761px) {
    .progress-bonus span{
      font-size: 1.2rem;
    }
  }

  /* Mobile devices */
  @media (max-width: 760px) {
    .progress-bonus span{
      font-size: 0.9rem;
    }
    .modal-content {
      width: 100%;
    }

    .modal-content {
      padding: 30px;
    }
  }
</style>