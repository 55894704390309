<template>
    <div class="container agb-container">
        <h1>AGB</h1>
        <br>
        <h4>§1 Allgemeines / Geltungsbereich</h4>
        <br>
        <ul>
            <li>Die folgenden Allgemeinen Geschäftsbedingungen gelten für alle gegenwärtigen und zukünftigen Geschäftsbeziehungen.</li>
            <li>Abweichende, entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen werden, selbst bei Kenntnis, nicht Vertragsbestandteil, es sei denn, ihrer Geltung wird ausdrücklich schriftlich zugestimmt.</li>
            <li>Dem Auftraggeber ist bekannt, dass die vom Auftragnehmer gelieferten Produkte im Ausland hergestellt sind, falls keine andere ausdrückliche Vereinbarung getroffen wird. Die gelieferten Produkte entsprechen dem deutschen Qualitätsstandard.</li>
        </ul>
        <br>
        <h4>§2 Vertragsschluss</h4>
        <br>
        <ul>
            <li>Kostenvoranschläge / Angebote sind freibleibend. Sie beziehen sich auf die am Tage der</li>
            <li>Ausstellung gültige Preisliste. Der Auftragnehmer ist berechtigt, Kostensteigerungen der bei der Erstellung verwendeten Materialien (Keramik, Edelmetall etc.) zwischen Kostenvoranschlag / Angebot und Liefertermin an den Auftraggeber weiterzugeben. Der Auftraggeber erklärt sich mit einer Erhöhung des Angebotspreises aus dem Kostenvoranschlag / Angebot bis 10% einverstanden, ohne dass der Auftraggeber gesondert zu informieren ist.</li>
            <li>Bei einer Erhöhung um mehr als 10% informiert der Auftragnehmer den Auftraggeber unter Angabe einer Begründung. Der Auftraggeber hat das Recht der Preiserhöhung innerhalb von 10 Tagen ab Datum des Informationsschreibens zu widersprechen.</li>
            <li>Danach gilt der erhöhte Preis als genehmigt.</li>
            <li>Die Kostenvoranschläge / Angebote beruhen auf einer geschätzten Edelmetallmenge, die je nach Beschaffenheit der Zahnstruktur des einzelnen Patienten variieren kann. Es handelt sich daher nur um Circa-Mengen.</li>
            <li>Entscheidende Bedeutung für den Sitz der Arbeit im Munde haben die Qualität der vom Auftraggeber eingesandten Modelle und Abformungen. Arbeitsunterlagen, die mangelhaft erscheinen, kann der Auftragnehmer unter Rücksprache und Absprache mit dem Auftraggeber zurücksenden.</li>
        </ul>
        <br>
        <h4>§3 Eigentumsvorbehalt</h4>
        <br>
        <ul>
            <li>Der Auftragnehmer behält sich das Eigentum an den gelieferten Arbeiten bis zur vollständigen Begleichung aller Forderungen aus der laufenden Geschäftsbeziehung vor.</li>
            <li>Der Auftraggeber ist berechtigt, die Arbeiten bei der Behandlung zu verwenden. Er tritt jedoch bereits jetzt alle Forderungen in Höhe des Rechnungsbetrages ab, die ihm im Zusammenhang mit der Behandlung unter Benutzung der Arbeiten erwachsen. Der Auftragnehmer nimmt die Abtretung hiermit an.</li>
            <li>Nach der Abtretung ist der Auftraggeber zur Einziehung der Forderung ermächtigt. Der Auftragnehmer behält sich vor, die Forderung selbst einzuziehen, sobald der Auftraggeber seinen Zahlungsverpflichtungen nicht ordnungsgemäß nachkommt und in Zahlungsverzug gerät.</li>
        </ul>
        <br>
        <h4>§4 Lieferzeit</h4>
        <br>
        <ul>
            <li>Die angegebene Lieferfrist ist unverbindlich. Die Voraussetzung für den Verzugseintritt ist das Setzen einer Nachfrist, die mindestens zwei Wochen betragen muss.</li>
        </ul>
        <br>
        <h4>§5 Vergütung</h4>
        <br>
        <ul>
            <li>Der Auftragnehmer stellt die Arbeiten monatlich zum Ende des Monats in Rechnung.</li>
            <li>Zahlungsweise per Vorkasse, Nachnahme oder per Rechnung.</li>
            <li>Für die Zahlung per Rechnung behalten wir uns eine Bonitätsprüfung durch Institutionen unserer Wahl vor. Negative Auskünfte können in einer Anpassung der Zahlungsweise oder maximalen Limitierung der monatlichen Umsatzhöhe resultieren. Die Rechnungen sind binnen dreißig Tagen ab Rechnungsdatum zur Zahlung fällig. Nach Ablauf dieser Frist kommt der Kunde in Zahlungsverzug. Einzelrechnungen werden fällig zum Monatsende mit der Sammel-aufstellung. Bei Zahlung innerhalb des Zahlungszieles gewährt der Auftragnehmer dem Auftraggeber einen Skontobetrag in Höhe von 2%.</li>
            <li>Der Auftraggeber hat während des Verzuges die Geldschulden in Höhe von 8% über dem Basiszinssatz zu verzinsen. Der Nachweis und die Geltendmachung eines höheren Verzugs-schadens bleibt vorbehalten.</li>
            <li>Ein Recht des Auftraggebers zur Aufrechnung besteht nur, wenn die Gegenansprüche rechtskräftig festgestellt wurden oder anerkannt oder unbestritten sind. Gleiches gilt für die Ausübung eines Zurückbehaltungsrechtes. Ein Zurückbehaltungsrecht kann im übrigen nur ausgeübt werden, wenn der Gegenanspruch auf demselben Vertragsverhältnis beruht.</li>
            <li>Der Auftragnehmer ist berechtigt, seine Forderungen gegen den Auftraggeber an eine</li>
            <li>Factoringgesellschaft abzutreten, die an seine Stelle als Forderungsinhaber tritt. In diesem Fall erlöschen die Zahlungsansprüche des Auftragnehmers mit der Abtretung. Die Rechnungs-beträge sind dann an die Factoringgesellschaft zu leisten. Gewährte Skonti werden von der Abtretung nicht berührt.</li>
        </ul>
        <br>
        <h4>§6 Versand, Gefahrenübergang</h4>
        <br>
        <ul>
            <li>Die Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung der Ware geht mit der Übergabe bzw. Auslieferung an den Spediteur, den Frachtführer oder der sonst zur</li>
            <li>Ausführung der Versendung bestimmten Personen oder Anstalten auf den Auftraggeber über.</li>
            <li>Der Übergabe steht es gleich, wenn der Auftraggeber in Verzug mit der Annahme ist.</li>
            <li>Grundsätzlich wird die Ware auf Kosten des Auftraggebers innerhalb Deutschlands versandt. Die Abholung durch den Auftraggeber oder Beauftragte kann vereinbart werden.</li>
        </ul>
        <br>
        <h4>§7 Haftung</h4>
        <br>
        <ul>
            <li>Der Auftragnehmer leistet nach seiner Wahl für Mängel der Arbeiten Gewährleistung durch Nachbesserung oder Ersatzlieferung.</li>
            <li>Schlägt die Nacherfüllung fehl, kann der Auftraggeber grundsätzlich nach seiner Wahl Herabsetzung der Vergütung (Minderung) oder Rückgängigmachung des Vertrages (Rücktritt) verlangen. Bei einer nur geringfügigen Vertragswidrigkeit, insbesondere bei nur geringfügigen Mängeln steht dem Auftraggeber jedoch kein Rücktrittsrecht zu.</li>
            <li>Der Auftraggeber muss Mängel innerhalb einer Frist von zwei Wochen ab Erhalt der Ware schriftlich anzeigen; andernfalls ist die Geltendmachung eines Gewährleistungsanspruchs ausgeschlossen. Bei Passungenauigkeiten muss die Mängelrüge unter Vorlage der Erstmodelle erfolgen; neue Abformungen sind beizufügen bzw. unverzüglich nachzureichen. Zur Fristwahrung genügt die rechtzeitige Absendung. Den Auftraggeber trifft die volle Beweislast für sämtliche Anspruchsvoraussetzungen, insbesondere für den Mangel selbst, für den Zeitpunkt der Feststellung des Mangels und für die Rechtzeitigkeit der Mängelrüge. Wählt der Kunde wegen eines Mangels nach gescheiterter Nacherfüllung Rücktritt vom Vertrag, steht ihm daneben kein Schadenersatzanspruch wegen des Mangels zu.</li>
            <li>Bei leicht fahrlässigen Pflichtverletzungen beschränkt sich die Haftung des Auftragnehmers auf den nach der Art der Arbeit vorhersehbaren, vertragstypischen unmittelbaren Durchschnittsschaden. Dies gilt auch bei leicht fahrlässigen Pflichtverletzungen der gesetzlichen Vertreter oder Erfüllungshilfen. Eine Haftung bei leicht fahrlässiger Verletzung unwesentlicher Vertragspflichten besteht nicht. Die vorstehende Haftungsbeschränkung betrifft nicht Ansprüche des Auftraggebers aus Produkthaftung. Weiterhin gelten die Haftungsbeschränkungen nicht bei zurechenbaren Körper- und Gesundheitsschäden oder Verlust des Lebens.</li>
            <li>Eine Haftung für Mängel, die aufgrund fehlerhafter Modelle und Abformungen des Auftraggebers entstehen, ist ausgeschlossen.</li>
            <li>Die Gewährleistungsfrist beträgt fünf Jahre ab Rechnungsdatum der Ware. Die gesetzlich verlängerte Gewährleistung erhält nur Gültigkeit durch die jährliche Untersuchung des Patienten durch den behandelnden Zahnarzt. Eine Dokumentation dieser muss durch Unterschrift des Patienten und Praxisstempel im Patientenpass erfolgt sein.</li>
            <li>Als Beschaffenheit der Ware gilt grundsätzlich nur die Produktbeschreibung des Herstellers als vereinbart. Öffentliche Äußerung, Anpreisungen oder Werbung stellen daneben keine vertragsgemäße Beschaffenheitsangabe der Ware dar.</li>
        </ul>
        <br>
        <h4>§8 Material und Zubehörteilstellung</h4>
        <br>
        <ul>
            <li>Vom Auftraggeber angelieferte Materialen /Edelmetall, Zähne, etc.) oder Zubehörteile (Fertigteile, z.B. Geschiebe, Gelenke, etc.) könne mit einem handelsüblichen Verarbeitungszu-schlag belegt werden. Mängel aufgrund fehlerhafter vom Auftraggeber angelieferter Materialien oder Zubehörteile gehen nicht zu Lasten des Auftragnehmers. Für die Aufbewahrung der vom Auftraggeber angelieferten Materialien oder Zubehörteile haftet der Auftragnehmer mit der Sorgfalt, die er in eigenen Angelegenheiten aufwendet.</li>
        </ul>
        <br>
        <h4>§9 Schlussbestimungen</h4>
        <br>
        <ul>
            <li>Es gilt das Recht der Bundesrepublik Deutschland. Die Bestimmungen des UN-Kaufrechtes finden keine Anwendung.</li>
            <li>Gerichtsstand für alle Streitigkeiten aus diesem Vertrag ist der Geschäftssitz des Auftrag-nehmers.</li>
            <li>Sollten einzelne Bestimmungen des Vertrages einschließlich dieser Allgemeinen Geschäftsbedingungen ganz oder teilweise unwirksam sein oder werden, wird hierdurch die Gültigkeit der übrigen Bestimmungen nicht berührt. Die ganz oder teilweise unwirksame Regelung soll durch eine Regelung ersetzt werden, deren wirtschaftlicher Erfolg dem der unwirksamen möglichst nahe kommt.</li>
        </ul>
        <br>
    </div>
</template>

<script>
export default {
    name: 'Agb',
}
</script>

<style lang="scss">
    .agb-container{
    color: #17243d;
    font-family: "NotoSans-Light";
        h1{
            font-size: 2rem;
            font-weight: 100;
        }

        h4{
            font-size: 1.74rem;
            font-weight: 100;
        }
        p{
            font-size: 1rem;

        }

        padding: 40px 100px;
    }
</style>