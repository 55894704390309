<template>
    <div class="container questions-container">
    <br>
        <h1>Umfragebogen</h1>
        <br>
        <h2>
            Zu bewerten in Schulnoten von 1 (sehr gut) bis 6 (ungenügend).
        </h2>
        <br>
        <form action="#" @submit.prevent="saveAnswers">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div>
                                <div class="row" style="color: #212529; font-weight: 500;">
                                    <div class="col">
                                        TECHNIK
                                    </div>
                                    <div class="col">
                                        Passgenauigkeit
                                    </div>
                                    <div class="col">
                                        Okklusion
                                    </div>
                                    <div class="col">
                                        Farbe
                                    </div>
                                </div><br>
                                <div class="row">
                                    <div class="col">
                                        Kronen/Brücken
                                    </div>
                                    <div class="col">
                                        <select v-model="kronen_p" id="kronen-p">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="kronen_o" id="kronen-o">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="kronen_f" id="kronen-f">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>    
                                </div>
                                <div style="height:10px;"></div>
                                <div class="row">
                                    <div class="col">
                                        Kombi
                                    </div>
                                    <div class="col">
                                        <select v-model="kombi_p" id="kombi-p">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="kombi_o" id="kombi-o">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="kombi_f" id="kombi-f">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>    
                                </div>
                                <div style="height:10px;"></div>
                                <div class="row">
                                    <div class="col">
                                        Kunststoff/Totale
                                    </div>
                                    <div class="col">
                                        <select v-model="kunststoff_p" id="kunststoff-p">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="kunststoff_o" id="kunststoff-o">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="kunststoff_f" id="kunststoff-f">
                                            <option disabled selected>Auswählen</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                        </select>
                                    </div>    
                                </div><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    ÄSTHETIK
                                </div>
                                <div class="col">
                                    Oberflächentextur
                                </div>
                                <div class="col">
                                    Korrekte Anatomie
                                </div>
                                <div class="col">
                                    Harmonisches Gesamtbild
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    
                                </div>
                                <div class="col">
                                    <select v-model="aesthtik_o" id="aesthtik-o">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="aesthtik_k" id="aesthtik-k">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="aesthtik_h" id="aesthtik-h">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    TECHNISCHER SERVICE
                                </div>
                                <div class="col">
                                    Erreichbarkeit der Technikhotline
                                </div>
                                <div class="col">
                                    Kompetenz der Mitarbeiter/innen
                                </div>
                                <div class="col">
                                    Freundlichkeit der Mitarbeiter/innen
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    Telefonische Hilfe
                                </div>
                                <div class="col">
                                    <select v-model="telefonische_e" id="telefonische-e">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="telefonische_k" id="telefonische-k">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="telefonische_f" id="telefonische-f">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>    
                            </div><br>
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                </div>
                                <div class="col">
                                    Schnelle Erledigung bei Problemen
                                </div>
                                <div class="col">
                                    Ausführung der Änderungen/Reparaturen
                                </div>
                                <div class="col">
                                    Leistungen der Service-Labore vor Ort
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    Kulanzabwicklung
                                </div>
                                <div class="col">
                                    <select v-model="kulanzabwicklung_s" id="kulanzabwicklung-s">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="kulanzabwicklung_a" id="kulanzabwicklung-a">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="kulanzabwicklung_l" id="kulanzabwicklung-l">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>    
                            </div><br>
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                </div>
                                <div class="col">
                                    Kompetenz der Mitarbeiter/innen
                                </div>
                                <div class="col">
                                    Korrekte Erstellung der Rechnungen
                                </div>
                                <div class="col">
                                    Freundlichkeit der Mitarbeiter/innen
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    Rechnungserstellung
                                </div>
                                <div class="col">
                                    <select v-model="rechnungserstellung_s" id="rechnungserstellung-s">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="rechnungserstellung_k" id="rechnungserstellung-k">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="rechnungserstellung_f" id="rechnungserstellung-f">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    ALLGEMEINE HOTLINE
                                </div>
                                <div class="col">
                                    Schnelle Erledigung/Antwort
                                </div>
                                <div class="col">
                                    Kompetenz/Korrekter KVA
                                </div>
                                <div class="col">
                                    Freundlichkeit der Mitarbeiter/innen
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    Kostenvoranschläge
                                </div>
                                <div class="col">
                                    <select v-model="kosten_s" id="kosten-s">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="kosten_k" id="kosten-k">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="kosten_f" id="kosten-f">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>    
                            </div><br>
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                </div>
                                <div class="col">
                                    Freundlichkeit der Mitarbeiter/innen
                                </div>
                                <div class="col">
                                    Termingerechte Abholung/Zustellung
                                </div>
                                <div class="col">
                                    Zufriedenheit mit der Hilfestellung
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    Versand
                                </div>
                                <div class="col">
                                    <select v-model="versand_f" id="versand-f">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="versand_t" id="versand-t">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                                <div class="col">
                                    <select v-model="versand_z" id="versand-z">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>    
                            </div>
                        </div>
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    Wie bewerten Sie unseren Außendienst?
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    <select v-model="aussendienst_z" id="aussendienst-z">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>  
                            </div>
                        </div>    
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    Wie zufrieden sind Sie mit unseren Lieferzeiten?
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    <select v-model="lieferzeiten_z" id="lieferzeiten-z">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>  
                            </div>
                        </div>    
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    Wie zufrieden sind Sie mit unserer Termintreue?
                                </div>
                            </div><br><br>
                            <div class="row">
                                <div class="col">
                                    <select v-model="termintreue_z" id="termintreue-z">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>  
                            </div>
                        </div>    
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    Wie bewerten Sie IMEX® im direkten Vergleich mit anderen Anbietern für Auslandszahnersatz?
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    <select v-model="vergleich_z" id="vergleich-z">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>  
                            </div>
                        </div>    
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                Wie bewerten Sie unsere preislichen Konditionen gegenüber anderen Anbietern für Zahnersatz?
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    <select  v-model="konditionen_z" id="konditionen-z">
                                        <option disabled selected>Auswählen</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                    </select>
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    Benutzen Sie einen Intraoral-Scanner? Welches Fabrikat falls JA?
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    <select v-model="intraoral_z" id="intraoral-z">
                                        <option disabled selected>Auswählen</option>
                                        <option value="JA">JA</option>
                                        <option value="NEIN">NEIN</option>
                                    </select>
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    <input maxlength="500" style="width: 100%; padding: 5px;" v-model="scanner_intraoral" id="scanner-intraoral" placeholder="Ihre Begründung falls JA" />
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    Nutzen Sie bereits die Möglichkeit Ihren CaseManager bei technischen Rückfragen per WhatsApp zu kontaktieren?
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    <select v-model="casemanager_z" id="casemanager-z">
                                        <option disabled selected>Auswählen</option>
                                        <option value="JA">JA</option>
                                        <option value="NEIN">NEIN</option>
                                    </select>
                                </div>  
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    <input maxlength="500" style="width: 100%; padding: 5px;" v-model="begruendung" id="begruendung" placeholder="Ihre Begründung falls NEIN" />
                                </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <div class="row" style="color: #212529; font-weight: 500;">
                                <div class="col">
                                    Haben Sie weitere Anregungen?
                                </div>
                            </div><br>
                            <div class="row">
                                <div class="col">
                                    <textarea maxlength="500" style="width: 100%; padding: 5px;" v-model="anregungen" id="anregungen"></textarea>
                                </div>
                            </div>
                        </div>    
                    </div><br>
                    <button type="submit" class="btn btn-success">SENDEN</button>
                </div>
            </div>
        </form>
        <br>
    </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Questions',
    data: () => ({
        kronen_p: '',
        kronen_o: '',
        kronen_f: '',
        kombi_p: '',
        kombi_o: '',
        kombi_f: '',
        kunststoff_p: '',
        kunststoff_o: '',
        kunststoff_f: '',
        aesthtik_o: '',
        aesthtik_k: '',
        aesthtik_h: '',
        telefonische_e: '',
        telefonische_k: '',
        telefonische_f: '',
        kulanzabwicklung_s: '',
        kulanzabwicklung_a: '',
        kulanzabwicklung_l: '',
        rechnungserstellung_s: '',
        rechnungserstellung_k: '',
        rechnungserstellung_f: '',
        kosten_s: '',
        kosten_k: '',
        kosten_f: '',
        versand_f: '',
        versand_t: '',
        versand_z: '',
        aussendienst_z: '',
        lieferzeiten_z: '',
        termintreue_z: '',
        vergleich_z: '',
        konditionen_z: '',
        intraoral_z: '',
        scanner_intraoral: '',
        casemanager_z: '',
        begruendung: '',
        anregungen: '',
    }),
    computed: {
        ...mapGetters({
            userDataVue: 'auth/user',
            getAlreadyVoted: 'umfrage/checkQuestionSent'
        })
    },
    methods: {
        ...mapActions({
            setConfirmed: 'umfrage/setAfterSentSuccess',
        }),
        checkFields() {
            if( this.kronen_p === "" || this.kronen_o === "" || this.kronen_f === "" ||
                this.kombi_p === "" || this.kombi_o === "" || this.kombi_f === "" ||
                this.kunststoff_p === "" || this.kunststoff_o === "" || this.kunststoff_f === "" ||
                this.aesthtik_o === "" || this.aesthtik_k === "" || this.aesthtik_h === "" ||
                this.telefonische_e === "" || this.telefonische_k === "" || this.telefonische_f === "" ||
                this.kulanzabwicklung_s === "" || this.kulanzabwicklung_a === "" || this.kulanzabwicklung_l === "" ||
                this.rechnungserstellung_s === "" || this.rechnungserstellung_k === "" || this.rechnungserstellung_f === "" ||
                this.kosten_s === "" || this.kosten_k === "" || this.kosten_f === "" ||
                this.versand_f === "" || this.versand_t === "" || this.versand_z === "" ||
                this.aussendienst_z === "" ||
                this.lieferzeiten_z === "" ||
                this.termintreue_z === "" ||
                this.vergleich_z === "" ||
                this.konditionen_z === "" ||
                this.intraoral_z === "" ||
                this.casemanager_z === ""
            ){
                this.$swal({
                    icon: 'warning',
                    title: 'Info',
                    text: 'Alle Felder müssen ausgefüllt werden',
                })
                return
            }
            return true
        },
        alreadyVoted(){
            if(this.getAlreadyVoted) {
                this.$swal({
                    icon: 'warning',
                    title: 'Info',
                    text: 'Sie haben bereits an der Umfrage teilgenommen',
                })
                return
            }
            return true;
        },
        async saveAnswers(){  
            if(this.alreadyVoted() && this.checkFields()){
                try {
                    let response = await axios
                    .get('create-question', { 
                        params: {
                            user_id: this.userDataVue.id,
                            kronen_p: this.kronen_p,
                            kronen_o: this.kronen_o,
                            kronen_f: this.kronen_f,
                            kombi_p: this.kombi_p,
                            kombi_o: this.kombi_o,
                            kombi_f: this.kombi_f,
                            kunststoff_p: this.kunststoff_p,
                            kunststoff_o: this.kunststoff_o,
                            kunststoff_f: this.kunststoff_f,
                            aesthtik_o: this.aesthtik_o,
                            aesthtik_k: this.aesthtik_h,
                            aesthtik_h: this.aesthtik_k,
                            telefonische_e: this.telefonische_e,
                            telefonische_k: this.telefonische_k,
                            telefonische_f: this.telefonische_f,
                            kulanzabwicklung_s: this.kulanzabwicklung_s,
                            kulanzabwicklung_a: this.kulanzabwicklung_a,
                            kulanzabwicklung_l: this.kulanzabwicklung_l,
                            rechnungserstellung_s: this.rechnungserstellung_s,
                            rechnungserstellung_k: this.rechnungserstellung_k,
                            rechnungserstellung_f: this.rechnungserstellung_f,
                            kosten_s: this.kosten_s,
                            kosten_k: this.kosten_k,
                            kosten_f: this.kosten_f,
                            versand_f: this.versand_f,
                            versand_t: this.versand_t,
                            versand_z: this.versand_z,
                            aussendienst_z: this.aussendienst_z,
                            lieferzeiten_z: this.lieferzeiten_z,
                            termintreue_z: this.termintreue_z,
                            vergleich_z: this.vergleich_z,
                            konditionen_z: this.konditionen_z,
                            intraoral_z: this.intraoral_z,
                            scanner_intraoral: this.scanner_intraoral,
                            casemanager_z: this.casemanager_z,
                            begruendung: this.begruendung,
                            anregungen: this.anregungen,
                        }
                    })
                    .then(() => {
                        this.setConfirmed()
                        this.$router.replace({ name: 'questionnaire-confirmed' })
                    })
                    return response
                } catch (e) { 
                    console.log(e)
                }
            }
        }
    }
}
</script>
<style lang="scss">
    .col{
        select{
            border: 2px solid #e5e7eb;
        }
    }

    .col {
        input {
            border: 2px solid #e5e7eb;
        }
    }

    .col {
        textarea {
            border: 2px solid #e5e7eb;
        }
    }

    .questions-container{
        h1{
            color: #17243d;
            font-family: "NotoSans-Medium";
            font-size: 2.1rem;
            font-weight: 100;
        }

        h2{
            font-size: 1.5rem;
        }
    }
</style>