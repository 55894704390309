<template>
    <div class="alert alert-danger" role="alert" v-if="!messageLoading">
        Die Online-Abholung steht zurzeit aus technischen Gründen nicht zur Verfügung. Wir arbeiten daran und bitten um Entschuldigung für die Unannehmlichkeiten. Ihre Online-Abholung können Sie jederzeit über die Telefonnummer 0201749990 beauftragen.
    </div>
    <div class="container pickup-container">
        <form action="#" @submit.prevent="makePickupDraft">
            <br>
            <h1>Abholung vorbereiten</h1>
            <br>
            <h2>Versanddaten</h2>
            <br>
            <h6>Adresse Abholung</h6>
            <pickup-adress ref="praxis_adress"></pickup-adress>
            <br>
            <div style="border: 1px solid #8b919e;"></div>
            <br>
            <h6>Anzahl Koffer pro Sendung</h6>
            <select class="form-select" v-model="packages">
                <option value="1">1 Koffer</option>
                <option value="2">2 Koffer</option>
                <option value="3">3 Koffer</option>
                <option value="4">4 Koffer</option>
                <option value="5">5 Koffer</option>
            </select>
            <br>
            <h6>Datum Mo. - Fr.</h6>
            <Datepicker calendarCellClassName="dp-custom-cell" hideOffsetDates  uid="date" :format="format" v-model="date_pickup" :minDate="new Date()" weekStart="1" :disabledWeekDays="[6, 0]" :enableTimePicker="false" class="dp-custom-input dp-custom-cell custom-pick" placeholder="Datum auswählen" autoApply/>
            <br>
            <h6>Uhrzeit 08:00 - 19:00 Uhr (Letzter Auftrag bis 17:00 Uhr möglich)</h6>
            <div class="row">
                <div class="col-sm-6">
                    <Datepicker selectText="Auswählen" cancelText="Abbrechen" v-model="fromTime" timePicker placeholder="Von" :minTime="{ hours: 8, minutes: 0 }" :autoPosition="false" class="dp-custom-input dp-custom-cell custom-pick" :filters="timeFilter"/>
                    <br>
                </div>

                <div class="col-sm-6">
                    <Datepicker selectText="Auswählen" cancelText="Abbrechen" v-model="toTime" timePicker placeholder="Bis"  :maxTime="{ hours: 19, minutes: 0 }" :autoPosition="false" class="custom-pick" :filters="timeFilter"/>
                </div>
            </div>
            <h6>Optionale Nachricht</h6>
            <div class="input-group mb-3">
                <input maxlength="35" type="text" class="form-control custom-inp" placeholder="max. 35 Zeichen" v-model="optional">
            </div>
            <br>
            <div class="button-upload" v-if="isUploading">
                <button type="submit"><strong>Abholung vorbereiten</strong></button>
            </div>
            <div class="button-upload-disabled" v-if="!isUploading">
                <button class="btn" type="button" disabled>
                    <span class="spinner-border spinner-border-m-5 text-primary" role="status" aria-hidden="true"></span>
                    <span class="sr-only">Laden...</span>
                </button>
            </div>
            <br>
            <br>
        </form>
    </div>
    <div id="box" v-if="isModal">
        <div id="insidebox">
            <div class="insidebox-top">
                <div class="insidebox-top-title">
                    <h3><strong>ABHOLUNG ÜBERSICHT</strong></h3>
                </div>
                <br>
                
                <div class="row row-inside-top">
                    <div class="col col-inside-top-left">
                        <p><strong>Anzahl Koffer</strong></p>
                        <p><strong>Datum</strong></p>
                        <p><strong>Startzeit</strong></p>
                        <p><strong>Endzeit</strong></p>
                        <p><strong>Optional</strong></p>
                    </div>
                    <div class="col col-inside-top-right">
                        <p>: {{ koffer_go }}</p>
                        <p>: {{ datum_go }}</p>
                        <p>: {{ timefrom_go }}</p>
                        <p>: {{ timeto_go }}</p>
                        <p>: {{ optional_go }}</p>
                    </div>
                </div>
            </div>
            <div class="insidebox-bottom">
                <hr/>
                <br>
                <p><strong>Hinweis: Die Abholung wurde vorbereitet, aber noch nicht beauftragt. Welche Aktion möchten Sie ausführen?</strong></p>
                <br>
                <div class="row">
                    <div class="col-sm-4 button-next button-next-edit">
                        <form action="#" @submit.prevent="openEditDraft">
                            <button type="submit"><strong>Bearbeiten</strong></button>
                        </form>
                    </div>
                    <div class="col-sm-4 button-next button-next-cancel">
                        <form action="#" @submit.prevent="cancelPickup">
                            <div v-if="isLoadingCancel">
                                <button type="submit"><strong>Stornieren</strong></button>
                            </div>
                            <div v-if="!isLoadingCancel">    
                                <button class="btn" type="button" disabled>
                                    <span class="spinner-border spinner-border-m-5" role="status" aria-hidden="true"></span>
                                    <span class="sr-only">Laden...</span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-4 button-next button-next-send">
                        <form action="#" @submit.prevent="sendDraftToGo">
                            <div v-if="isLoadingSend">
                                <button type="submit"><strong>Beauftragen</strong></button>
                            </div>
                            <div v-if="!isLoadingSend">
                                <button class="btn" type="button" disabled>
                                    <span class="spinner-border spinner-border-m-5" role="status" aria-hidden="true"></span>
                                    <span class="sr-only">Laden...</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import PickupAdress from '@/components/Menu/Scandaten/PickupAdress'
    import axios from "axios";
    import Datepicker from 'vue3-date-time-picker';
    import 'vue3-date-time-picker/dist/main.css'
    import { ref, computed } from 'vue';
    import { mapGetters, mapActions } from 'vuex'
    export default {
        name: 'Pickup',
        data: () => ({
            messageLoading  : true,
            isUploading     : true,
            isModal         : false,
            isLoadingCancel : true,
            isLoadingSend   : true,
            koffer_go       : "",
            datum_go        : "",
            timefrom_go     : "",
            timeto_go       : "",
            optional_go     : "",
            packages        : '1',
            optional        : "",
            hwbNumber       : "",
        }),
        setup() {
            const date_pickup = ref(new Date());
            const fromTime = ref({hours: new Date().getHours()+1, minutes: new Date().getMinutes(), seconds: 0});
            const toTime = ref({hours: new Date().getHours()+3, minutes: new Date().getMinutes(), seconds: 0});
            const format = (date_actual) => {
                const day = date_actual.getDate().toString().padStart(2, "0");
                const month = (date_actual.getMonth() + 1).toString().padStart(2, "0");
                const year = date_actual.getFullYear();
                return `${day}.${month}.${year}`;
            }
            const timeFilter = computed(() => {
                return {
                    times: {
                        hours: [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23, 24],
                    }
                }
            });
            return {
                timeFilter,
                date_pickup,
                format,
                fromTime,
                toTime,
            }
        },
        components: {
            PickupAdress,
            Datepicker
        },
        computed: {
            ...mapGetters({
                userDataVue: 'auth/user',
            })
        },
        methods: {
            ...mapActions({
                setConfirmed: 'go/setAfterSentSuccess',
                deleteToken : 'auth/removeTokenUser',
            }),
            validateFields(){
                if(this.packages === ""){
                    this.isUploading = true
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Anzahl der Koffer nicht ausgewählt',
                    })
                    return
                }
                if(this.date_pickup == null){
                    this.isUploading = true
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Datum muss ausgewählt werden',
                    })
                    return
                }

                if(this.fromTime == null || this.toTime == null){
                    this.isUploading = true
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Uhrzeit muss ausgewählt werden',
                    })
                    return
                }

                if(parseInt(this.fromTime.hours+("0" + this.fromTime.minutes).slice(-2)) > parseInt(this.toTime.hours+("0" + this.toTime.minutes).slice(-2))){
                    this.isUploading = true
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Die Startzeit darf nicht größer als die Endzeit sein',
                    })
                    return                    
                }
                
                var value_start_picked  = ((this.fromTime.hours)  + ":" + ("0" + this.fromTime.minutes).slice(-2) + ":" + ("0" + this.fromTime.seconds).slice(-2)).split(':')
                var value_end_picked    = ((this.toTime.hours)  + ":" + ("0" + this.toTime.minutes).slice(-2) + ":" + ("0" + this.toTime.seconds).slice(-2)).split(':')
                var valued_start_picked_timestamp   = new Date().setHours(value_start_picked[0], value_start_picked[1], value_start_picked[2], 0)
                var valued_end_picked_timestamp     = new Date().setHours(value_end_picked[0], value_end_picked[1], value_end_picked[2], 0)
                
                if(valued_end_picked_timestamp - valued_start_picked_timestamp < 7200000 ){
                    this.isUploading = true
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Zeitfenster muss 2 Stunden auseinander liegen',
                    })
                    return      
                }

                if(("0" + value_start_picked[0]).slice(-2) +":"+value_start_picked[1]+":"+value_start_picked[2] > "17:00:00"){
                    this.isUploading = true
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Letzter Auftrag bis 17:00 möglich',
                    })
                    return
                }

                if(this.formatDateHuman(new Date(this.date_pickup))===this.formatDateHuman(new Date()) && Number(new Date()) + 60000 > valued_start_picked_timestamp ){
                    this.isUploading = true
                    var minute = new Date().getMinutes() + 1
                    if(new Date().getMinutes() < 10) {
                        if(new Date().getMinutes()+1 == "10"){
                            minute = "10"
                        } else {
                            minute = "0"+(new Date().getMinutes()+1)
                        }
                    }
                    var time = (new Date().getHours())  + ":" + minute;
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Die Startzeit darf nicht unter '+ time +' Uhr liegen',
                    })
                    return      
                }
                return true;
            },
            formatDateHuman(date){
                let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
                let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
                let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
                return `${day}.${month}.${year}`
            },
            async makePickupDraft(){     
                this.isUploading = false
                if(this.validateFields()){
                    try {
                        let fromTimeConverted,toTimeConverted

                        var hourFrom    = this.fromTime.hours
                        var minutesFrom = this.fromTime.minutes
                        var hourTo      = this.toTime.hours
                        var minutesTo   = this.toTime.minutes

                        if(this.fromTime.hours < 10){
                            hourFrom    = "0" + hourFrom
                        }
                        if(this.fromTime.minutes < 10){
                            minutesFrom = "0" + minutesFrom
                        }

                        if(this.toTime.hours < 10) {
                            hourTo = "0"+hourTo
                        }
                        
                        if(this.toTime.minutes < 10){
                            minutesTo = "0" + minutesTo
                        }

                        // if(this.toTime.minutes>=60){
                        //     fromTimeConverted   = (this.fromTime.hours)  + ":" + ("0" + this.toTime.minutes%60).slice(-2)
                        //     toTimeConverted     = (this.toTime.hours)  + ":" + ("0" + this.toTime.minutes%60).slice(-2)   
                        // } else {
                        //     fromTimeConverted   = (this.fromTime.hours)  + ":" + ("0" + this.fromTime.minutes).slice(-2)
                        //     toTimeConverted     = (this.toTime.hours)  + ":" + ("0" + this.toTime.minutes).slice(-2)     
                        // }     
                        
                        fromTimeConverted = hourFrom + ":" + minutesFrom
                        toTimeConverted = hourTo + ":" + minutesTo
                    
                        await axios
                        .get('create-go-draft', { 
                            params: {
                                packages    : this.packages,
                                datecustom  : this.formatDateHuman(this.date_pickup),
                                timeFrom    : fromTimeConverted,
                                timeTill    : toTimeConverted,
                                optional    : this.optional,
                                praxisname  : (this.$refs.praxis_adress.praxis.praxis).substring(0, 35),
                                praxisstreet: this.$refs.praxis_adress.praxis.street,
                                praxiszip   : this.$refs.praxis_adress.praxis.zip,
                                praxiscity  : this.$refs.praxis_adress.praxis.city,
                            }
                        })
                        .then((response) => {
                            this.hwbNumber = response.data.body.hwbNumber
                            return this.loadCustomModal(fromTimeConverted, toTimeConverted)
                        })
                    } catch (e) {
                        if(e.response.status === 401){
                            this.$toast.error(`Ihre Sitzung ist beendet, bitte melden Sie sich erneut an`,{
                                position: "top-right",
                                duration: 7000,
                            });
                            this.deleteToken()
                            this.$router.replace({
                                name: 'home'
                            })
                            return
                        }
                        this.$swal({
                            icon: 'error',
                            title: 'Fehler',
                            text: 'Bitte versuchen Sie es später erneut',
                        })
                        this.isUploading = true
                    }
                }
            },
            async loadCustomModal(fromTimeConverted, toTimeConverted){

                this.koffer_go      = this.packages
                this.datum_go       = this.formatDateHuman(this.date_pickup)
                this.timefrom_go    = fromTimeConverted
                this.timeto_go      = toTimeConverted
                this.optional_go    = this.optional
                this.isUploading    = true
                this.isModal        = true
            },
            openEditDraft(){
                this.$router.replace({ name: 'pickup-edit', params: { hwb: this.hwbNumber } })
            },
            async cancelPickup(){
                this.isLoadingCancel = false
                try {
                    let response = await axios
                    .get('cancel-go', { 
                        params: {
                            hwbNumber   : this.hwbNumber,
                            email       : this.userDataVue.username,
                        }
                    })
                    this.$swal({
                        icon : 'success',
                        title : 'Erledigt',
                        text : 'Abholung wurde storniert!',
                    })
                    this.isModal = false
                    this.isUploading = true
                    this.isLoadingCancel = true
                    return response
                } catch (e) {
                    this.isUploading = true
                    this.isLoadingCancel = true
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Bitte versuchen Sie es später erneut',
                    })
                }
            },
            async sendDraftToGo(){
                this.isLoadingSend = false
                try {
                    let response = await axios
                    .get('release-go', { 
                        params: {
                            hwbNumber   : this.hwbNumber,
                            email       : this.userDataVue.username,
                        }
                    })
                    .then(() => {
                        //activate SET_AFTER_SENT_SUCCESS = true, to allow one time to show the page "GoSuccess"
                        this.setConfirmed()
                        this.$router.replace({ name: 'pickupgo-confirmed', params: { hwb: this.hwbNumber } })
                    })  
                    return response
                } catch (e) {
                    this.isUploading = true
                    this.isLoadingSend = true
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Bitte versuchen Sie es später erneut',
                    })
                }
            },
            checkApi() {
                try {
                    axios
                    .get('check-api')
                    .catch(() => {
                        this.messageLoading = false   
                        this.isUploading = false 
                    })
                } catch (e) { 
                    this.isLoading = false
                    this.isUploading = false
                }
            }
        },
        mounted(){
            this.checkApi()
        } 
    }
</script>
<style lang="scss" scoped>
    /*ALERT START*/
    /* The alert message box */
    .alert {
        padding: 20px;
        background-color: #f8d7da; 
        color: #721c24;
        margin-bottom: 15px;
        border-color: #f5c6cb
    }
    
    .pickup-container{
    color: #17243d;
    font-family: "NotoSans-Light";
        h1{
            font-size: 2.1rem;
            font-weight: 100;
        }

        h2{
            font-size: 1.74rem;
            font-weight: 100;
        }
        h6{
            font-size: 1.125rem;
            margin-bottom: 6px;

        }

        hr{
            color: 2px solid #8b919e !important; 
        }
    }
    .insidebox-bottom{
        width: 100%;
        padding: 2vw;
        position:absolute; bottom:0;
    }

    .input-group{
        input[type=text] {
            background: #fff;
        }
    }

    .insidebox-top{

        .insidebox-top-title{
            background-color: #535D64;
            color: #fff;
            font-family: "NotoSans-Regular";
            padding: 1.5vw;
        }

        .row-inside-top{
            text-align: center;

            input[type="text"]
            {
                background: transparent;
                border: none;
            }

            .col-inside-top-left{
                text-align: right;
            }

            .col-inside-top-right{
                text-align: left;

                input{
                    width: 150px;
                }
            }
        }
    }

    .button-next{
        button{
            width: 100%;
            height: 3.5rem;
            color: #fff;
            border: none;
            border-radius: 0.3rem;
            outline: none;
            cursor: pointer;
            font-size: 1.2rem;
        }
    }

    .button-next-send{
        button{
            background-color: #81bb27;
        }

        button:hover{
            background-color: #04833d;
        }
    }

    .button-next-edit{
        button{
            margin-bottom: 5px;
            background-color: #239CED;
        }
        button:hover{
            background-color: #1A699E;
        }
    }

    .button-next-cancel{
        button{
            margin-bottom: 5px;
            background-color: #ED2323;
        }
        button:hover{
            background-color: #A41515;
        }
    }

    #box{
        width: auto;
        height: auto;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    #insidebox{
        background-color: #ecedf0;
        width: 800px;
        height: 550px;
        position: absolute;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0 30px 60px 53px rgb(0 0 0 / 40%), 0 34px 120px 0 rgb(0 0 0 / 20%) !important;
        
        h3{
            text-align: center;
        }
    }

    .button-upload{ 
        button{
            background-color: #81bb27;
            width: 100%;
            height: 3.5rem;
            color: #fff;
            border: none;
            border-radius: 0.3rem;
            outline: none;
            cursor: pointer;
            font-size: 1.2rem;
        }

        button:hover{
            background-color: #04833d;
        }
    }

    .button-upload-disabled{ 

        button{
            background-color: #C4BEBE;
            width: 100%;
            height: 3.5rem;
            color: #fff;
            border: none;
            border-radius: 0.3rem;
            outline: none;
            font-size: 1.2rem;
        }
    }

    .container{
        h6{
            font-family: "NotoSans-Regular";
        }
    }

    .dp-custom-cell {
        border-radius: 50%;
    }

    .custom-pick{
        div{
            .dp__input_wrap{
                input {
                    height: 60px;
                    text-align: center;
                    background: #ecedf0;
                    color: #8b919e!important;
                    font-size: 1.125rem;
                }
            }
        }
    }

    .form-select{
        height: 60px;
        text-align: center;
        background: #ecedf0;
        border: 1px solid #8b919e;
        font-family: "NotoSans-Medium";
        color: #8b919e!important;
        font-size: 1.125rem;
    }
    
    .custom-inp{
        height: 60px;
        border: 1px solid #8b919e;
        font-family: "NotoSans-Medium";
    }

    input[type="text"]{ 
        text-align: center;
        color: #8b919e!important;
        font-size: 1.125rem;
        font-family: "NotoSans-Regular";
    }

  @media (max-width: 760px) {
    #insidebox{
        width: 370px;
        height: 600px;          
    }

    .insidebox-top{
        .insidebox-top-title {
            padding: 3.5vw;
        }
        .row-inside-top {
            .col-inside-top-left {
                text-align: center;
            }
        }
    }
  }
</style>