import { createStore } from 'vuex'
import auth from './auth'
import scandata from './scandata'
import go from './go'
import umfrage from './umfrage'

export default createStore({
  state: {
  },

  mutations: {
  },

  actions: {
  },

  modules: {
    auth,
    scandata,
    go,
    umfrage,
  }
})
