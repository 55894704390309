
export default ({
    namespaced: true,
    state: {
        after_sent_success: false,
    },

    getters: {
        checkScanSent(state) {
            return state.after_sent_success
        },
    },

    mutations: {
        SET_AFTER_SENT_SUCCESS (state) {
            state.after_sent_success = true
        },
    },

    actions: {
        async setAfterSentSucess({commit}){
            commit('SET_AFTER_SENT_SUCCESS')
        },
    }
})
