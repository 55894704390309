<template>
    <div class="progress upload-status">
        <div class="progress-bar bg-success progress-bar-striped" role="progressbar" :style="{ width: getProgress }">
            {{ progress &lt; 100 ? getProgress : "Abgeschlossen!" }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        progress: {
            type: Number,
            default: 0,
        }
    },
    computed: {
        getProgress () {
            return Math.round(this.progress) + '%'
        }
    }

}
</script>

<style lang="css">
.upload-status {
    height: 3.5rem !important;
}
</style>