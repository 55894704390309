<template>
    <div  v-if="authenticated" class="col-sm-2 menu-custom">
        <ul>
            <router-link :to="{ name: 'dashboard' }"><li><p>Start</p></li></router-link>
            <router-link :to="{ name: 'kostenvoranschlag' }"><li><p>Kostenvoranschlag</p></li></router-link>
            <router-link :to="{ name: 'lieferzeiten' }"><li><p>Lieferzeit</p></li></router-link>
            <router-link :to="{ name: 'abholung' }"><li><p>Abholung</p></li></router-link>
            <router-link :to="{ name: 'scan-data' }"><li><p>Scandaten</p></li></router-link>
        </ul>
    </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
        })
    },
}
</script>
<style lang="scss" scoped>
    .collapse-custom li{
        border-bottom: none;
    }
</style>