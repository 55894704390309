<template>
    <div class="container info-container-dashboard">
        <h1>GO! Express für Ihre Abholungen</h1>
        <br>
        <div class="img-infos">
            <img src="@/assets/images/vw-go.5791107b1785d8bad6f9.jpg">
        </div>
        <br>
        <p>
            Mit dem Logistikunternehmen GO! setzen wir für Sie auf einen leistungsstarken Dienstleister. Der Produktbereich "Express" steht für eine Just-in-time-Logistik, mit der Ihre Aufträge zuverlässig abgeholt und fertige Arbeiten termingerecht geliefert werden. Bei Fragen rund um das Thema Abholungen rufen Sie uns unter 0201 - 74 999 0 an. Wir helfen Ihnen gerne weiter.
        </p>
    </div>
</template>

<script>
export default {
    name: 'Goexpress',
}
</script>