<template>
    <div class="container bestellungen-container">
        <br>
        <h1>Bestellungen</h1>
        <br>
        <h2>Abholungen - Übersicht</h2>
        <PickupList />
        <br>
        <br>
        <br>
        <h2>Scandaten - Übersicht</h2>
        <ScanDataList />
        <br>
    </div>
</template>
<script>
    import ScanDataList from '@/components/Menu/Scandaten/ScanDataList'
    import PickupList from '@/components/Menu/Pickup/PickupList'
    export default {
        name: 'Orders',
        components: {
            ScanDataList,
            PickupList,
        },
    }
</script>
<style lang="scss">
    .bestellungen-container{
    color: #17243d;
    font-family: "NotoSans-Medium";
        h1{
            font-size: 2.1rem;
            font-weight: 100;
            font-family: "NotoSans-Light";
        }

        h2{
            font-size: 1.74rem;
            font-weight: 100;
            margin-bottom: 20px;
            font-family: "NotoSans-Light";
        }
        p{
            font-size: 1rem;

        }
    }
</style>