import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Password from '../views/Password.vue'
import store from '@/store'
import Notfound from '../views/404Notfound.vue'

//Menu Views
import Questions from '../views/Questionnaire/Questionnaire.vue'
import QuestionsSuccess from '../views/Questionnaire/QuestionsSuccess.vue'

//Menu Views
import Dashboard from '../views/Menu/Dashboard.vue'
import Calculator from '../views/Menu/Calculator.vue'
import Scandata from '../views/Menu/Scandata/Scandata.vue'
import Pickup from '../views/Menu/Pickup/Pickup.vue'
import PickupEdit from '../views/Menu/Pickup/PickupEdit.vue'
import GoSuccess from '../views/Menu/Pickup/GoSuccess.vue'
import TimeEstimated from '../views/Menu/TimeEstimated.vue'
import ScandataUploaded from '../views/Menu/Scandata/ScandataUploaded.vue'
import Orders from '../views/Menu/Orders/Orders.vue'

//Profile Views
import Profile from '../views/Profile/Profile.vue'
import PasswordForgot from '../views/Profile/PasswordForgot.vue'

//Infos Views
// import Ikrone from '../views/Info/Ikrone.vue'
import iTeleskop from '../views/Info/iTeleskop.vue'
import Istraight from '../views/Info/Istraight.vue'
import Goexpress from '../views/Info/Goexpress.vue'

//Footer Views
import Datenschutz from '../views/Footer/Datenschutz.vue'
import Agb from '../views/Footer/Agb.vue'
import Impressum from '../views/Footer/Impressum.vue'

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: 
  [
    { 
      path: '/:pathMatch(.*)*', 
      name: 'not-found', 
      component: Notfound 
    },
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: (to, from, next) => {
        if(store.getters['auth/authenticated']){
          return next({
            name: 'dashboard',
          })
        }
        
        next();
      }
    },
    {
      path: '/password',
      name: 'password',
      component: Password,
      beforeEnter: (to, from, next) => {
        if(store.getters['auth/authenticated']){
          return next({
            name: 'dashboard',
          })
        }
        
        next();
      }
    },
    {
      path: '/questionnaire',
      name: 'questionnaire',
      component: Questions, 
      beforeEnter: (to, from, next) => {
        if(!store.getters['auth/authenticated'] || store.getters['auth/questionarie']){
          return next({
            name: 'dashboard',
          })
        }
        next();
      }
    },
    {
      path: '/questionnaire-success',
      name: 'questionnaire-confirmed',
      component: QuestionsSuccess,
      beforeEnter: (to, from, next) => {
        if(!store.getters['umfrage/checkQuestionSent']){
          return next({
            name: 'dashboard',
          })
        }
        
        next();
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard, 
      beforeEnter: (to, from, next) => {
        if(!store.getters['auth/authenticated']){
          return next({
            name: 'home',
          })
        }
        
        next();
      }
    },
    {
      path: '/abholungen/bestellungen',
      name: 'bestellungen',
      component: Orders, 
      beforeEnter: (to, from, next) => {
        if(!store.getters['auth/authenticated']){
          return next({
            name: 'home',
          })
        }
        
        next();
      }
    },
    {
      path: '/datenschutz',
      name: 'datenschutz',
      component: Datenschutz, 
    },
    {
      path: '/agb',
      name: 'agb',
      component: Agb, 
    },
    {
      path: '/impressum',
      name: 'impressum',
      component: Impressum, 
    },
    // {
    //   path: '/info/1',
    //   name: 'info1',
    //   component: Ikrone, 
    //   beforeEnter: (to, from, next) => {
    //     if(!store.getters['auth/authenticated']){
    //       return next({
    //         name: 'home',
    //       })
    //     }
        
    //     next();
    //   }
    // },
    {
      path: '/info/1',
      name: 'info1',
      component: iTeleskop, 
      beforeEnter: (to, from, next) => {
        if(!store.getters['auth/authenticated']){
          return next({
            name: 'home',
          })
        }
        
        next();
      }
    },
    {
      path: '/info/2',
      name: 'info2',
      component: Goexpress, 
      beforeEnter: (to, from, next) => {
        if(!store.getters['auth/authenticated']){
          return next({
            name: 'home',
          })
        }
        
        next();
      }
    },
    {
      path: '/info/3',
      name: 'info3',
      component: Istraight, 
      beforeEnter: (to, from, next) => {
        if(!store.getters['auth/authenticated']){
          return next({
            name: 'home',
          })
        }
        
        next();
      }
    },
    {
      path: '/benutzerkonto',
      name: 'profile',
      component: Profile, 
      beforeEnter: (to, from, next) => {
        if(!store.getters['auth/authenticated']){
          return next({
            name: 'home',
          })
        }
        
        next();
      }
    },
    {
      path: '/abholungen/kostenvoranschlag',
      name: 'kostenvoranschlag',
      component: Calculator, 
      beforeEnter: (to, from, next) => {
        if(!store.getters['auth/authenticated']){
          return next({
            name: 'home',
          })
        }
        
        next();
      }
    },
    {
      path: '/abholungen/scandaten',
      name: 'scan-data',
      component: Scandata, 
      beforeEnter: (to, from, next) => {
        if(!store.getters['auth/authenticated']){
          return next({
            name: 'home',
          })
        }
        
        next();
      }
    },
    {
      path: '/abholungen/lieferzeiten',
      name: 'lieferzeiten',
      component: TimeEstimated, 
      beforeEnter: (to, from, next) => {
        if(!store.getters['auth/authenticated']){
          return next({
            name: 'home',
          })
        }
        
        next();
      }
    },
    {
      path: '/abholungen/abholung',
      name: 'abholung',
      component: Pickup, 
      beforeEnter: (to, from, next) => {
        if(!store.getters['auth/authenticated']){
          return next({
            name: 'home',
          })
        }
        
        next();
      }
    },
    {
      path: '/abholungen/bearbeiten/:hwb',
      name: 'pickup-edit',
      component: PickupEdit, 
      beforeEnter: (to, from, next) => {
        if(!store.getters['auth/authenticated']){
          return next({
            name: 'pickup',
          })
        }
        
        next();
      }
    },
    {
      path: '/confirmed/pickupgo/:hwb',
      name: 'pickupgo-confirmed',
      component: GoSuccess,
      beforeEnter: (to, from, next) => {
        if(!store.getters['go/checkGoSent']){
          return next({
            name: 'pickup',
          })
        }
        
        next();
      }
    },
    {
      path: '/passwort-vergessen/:codeemail',
      name: 'passwort-vergessen',
      component: PasswordForgot,
    },
    {
      path: '/confirmed/scandaten',
      name: 'scandaten-confirmed',
      component: ScandataUploaded,
      beforeEnter: (to, from, next) => {
        if(!store.getters['scandata/checkScanSent']){
          return next({
            name: 'scan-data',
          })
        }
        
        next();
      }
    },
  ]
})

export default router
