<template>
    <div class="container container-custom">
        <div class="container-interior-dashboard">
            <div class="row">
                <Menu_Left />
                <div  class="col container-middle-custom">
                  <Time />
                </div>
                <div class="col-sm-3 menu-info-title">
                  <Menu_Right />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Menu_Left from '@/components/Menu_Left'
  import Time from '@/components/Menu/Time'
  import Menu_Right from '@/components/Menu_Right'
  export default {
    components: {
      Menu_Left,
      Time,
      Menu_Right
    },
  }
</script>
<style>
    /*ALERT START*/
    /* The alert message box */
    .alert {
    padding: 20px;
    background-color: #fbbc40; 
    color: white;
    margin-bottom: 15px;
    font-weight: bold;
    font-family: "NotoSans-Regular";
    }

    /* The close button */
    .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
    }
  </style>