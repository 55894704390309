<template>
    <div class="container pickup-container">
        <form action="#" @submit.prevent="editDraft">
            <br>
            <h1>Auftrag bearbeiten </h1>
            <br>
            <div class="pickup-adress" v-if="!isLoading">
                <div class="row">
                    <div class="col">
                        <p>Auftragsnummer : {{ url_data }}</p>
                    </div>
                </div>
            </div>
            <div class="pickup-adress" style="text-align: center;" v-if="isLoading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <br>
            <h6>Anzahl Koffer pro Sendung</h6>
            <select class="form-select" v-model="go.packages">
                <option value="1">1 Koffer</option>
                <option value="2">2 Koffer</option>
                <option value="3">3 Koffer</option>
                <option value="4">4 Koffer</option>
                <option value="5">5 Koffer</option>
            </select>
            <br>
            <h6>Datum Mo. - Fr.</h6>
            <Datepicker calendarCellClassName="dp-custom-cell" hideOffsetDates  uid="date" :format="format" v-model="date_pickup" :minDate="new Date()" weekStart="1" :disabledWeekDays="[6, 0]" :enableTimePicker="false" class="dp-custom-input dp-custom-cell custom-pick" placeholder="Datum auswählen" autoApply/>
            <br>
            <h6>Uhrzeit 08:00 - 19:00 Uhr (Letzter Auftrag bis 17:00 Uhr möglich)</h6>
            <div class="row">
                <div class="col-sm-6">
                    <Datepicker selectText="Auswählen" cancelText="Abbrechen" v-model="fromTime" timePicker placeholder="Von" :minTime="{ hours: 8, minutes: 0 }" :autoPosition="false" class="dp-custom-input dp-custom-cell custom-pick" :filters="timeFilter"/>
                    <br>
                </div>
                <div class="col-sm-6">
                    <Datepicker selectText="Auswählen" cancelText="Abbrechen" v-model="toTime" timePicker placeholder="Bis"  :maxTime="{ hours: 19, minutes: 0 }" :autoPosition="false" class="custom-pick" :filters="timeFilter"/>
                </div>
            </div>
            <h6>Optionale Nachricht</h6>
            <div class="input-group mb-3">
                <input maxlength="35" type="text" class="form-control custom-inp" placeholder="max. 35 Zeichen" v-model="go.optional">
            </div>
            <br>
            <div class="button-edit" v-if="isUploading">
                <button type="submit"><strong>Bearbeitung übernehmen</strong></button>
            </div>
            <div class="button-edit-disabled" v-if="!isUploading">
                <button disabled><strong>Laden ...</strong></button>
            </div>
            <br>
            <br>
        </form>
    </div>
    <div id="box" v-if="isModal">
        <div id="insidebox">
            <div class="insidebox-top">
                <div class="insidebox-top-title">
                    <h3><strong>ABHOLUNG ÜBERSICHT</strong></h3>
                </div>
                <br>
                
                <div class="row row-inside-top">
                    <div class="col col-inside-top-left">
                        <p><strong>Anzahl Koffer</strong></p>
                        <p><strong>Datum</strong></p>
                        <p><strong>Startzeit</strong></p>
                        <p><strong>Endzeit</strong></p>
                        <p><strong>Optional</strong></p>
                    </div>
                    <div class="col col-inside-top-right">
                        <p>: {{ koffer_go }}</p>
                        <p>: {{ datum_go }}</p>
                        <p>: {{ timefrom_go }}</p>
                        <p>: {{ timeto_go }}</p>
                        <p>: {{ optional_go }}</p>
                    </div>
                </div>
            </div>
            <div class="insidebox-bottom">
                <hr/>
                <br>
                <p><strong>Hinweis: Die Abholung wurde vorbereitet, aber noch nicht beauftragt. Welche Aktion möchten Sie ausführen?</strong></p>
                <br>
                <div class="row">
                    <div class="col-sm-4 button-next button-next-edit">
                        <form action="#" @submit.prevent="openEditDraft">
                            <button type="submit"><strong>Bearbeiten</strong></button>
                        </form>
                    </div>
                    <div class="col-sm-4 button-next button-next-cancel">
                        <form action="#" @submit.prevent="cancelPickup">
                            <div v-if="isLoadingCancel">
                                <button type="submit"><strong>Stornieren</strong></button>
                            </div>
                            <div v-if="!isLoadingCancel">    
                                <button class="btn" type="button" disabled>
                                    <span class="spinner-border spinner-border-m-5" role="status" aria-hidden="true"></span>
                                    <span class="sr-only">Laden...</span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div class="col-sm-4 button-next button-next-send">
                        <form action="#" @submit.prevent="sendDraftToGo">
                            <div v-if="isLoadingSend">
                                <button type="submit"><strong>Beauftragen</strong></button>
                            </div>
                            <div v-if="!isLoadingSend">
                                <button class="btn" type="button" disabled>
                                    <span class="spinner-border spinner-border-m-5" role="status" aria-hidden="true"></span>
                                    <span class="sr-only">Laden...</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";
    import Datepicker from 'vue3-date-time-picker';
    import 'vue3-date-time-picker/dist/main.css'
    import { ref, computed } from 'vue';
    import { mapGetters, mapActions } from 'vuex'
    export default {
        name: 'PickupEdit',
        data: () => ({
            isLoading       : true,
            isUploading     : true,
            isModal         : false,
            isLoadingCancel : true,
            isLoadingSend   : true,
            url_data        : null,
            koffer_go       : "",
            datum_go        : "",
            timefrom_go     : "",
            timeto_go       : "",
            optional_go     : "",
            go: {
                fromTime    : '',
                toTime      : '',
                date        : '',
                packages    : '',
                optional    : '',
            },
            name            : '',
            street          : '',
            zip             : '',
            number          : '',
            city            : '',
            hwbNumber       : '',
        }),
        setup() {
            const date_pickup = ref();
            const fromTime = ref();
            const toTime = ref();
            const format = (date_actual) => {
                const day = date_actual.getDate().toString().padStart(2, "0");
                const month = (date_actual.getMonth() + 1).toString().padStart(2, "0");
                const year = date_actual.getFullYear();
                return `${day}.${month}.${year}`;
            }
            const timeFilter = computed(() => {
                return {
                    times: {
                        hours: [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23, 24],
                    }
                }
            });
            return {
                timeFilter,
                date_pickup,
                format,
                fromTime,
                toTime,
            }
        },
        components: {
            Datepicker
        },
        computed: {
            ...mapGetters({
                userDataVue: 'auth/user',
            })
        },
        methods: {
            ...mapActions({
                setConfirmed: 'go/setAfterSentSuccess',
                deleteToken: 'auth/removeTokenUser',
            }),
            openEditDraft(){
                this.isModal = false
            },
            validateFields(){
                if(this.packages === ""){
                    this.isUploading = true
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Anzahl der Koffer nicht ausgewählt',
                    })
                    return
                }
                if(this.date_pickup == null){
                    this.isUploading = true
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Datum muss ausgewählt werden',
                    })
                    return
                }
    
                if(this.fromTime == null || this.toTime == null){
                    this.isUploading = true
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Uhrzeit muss ausgewählt werden',
                    })
                    return
                }
    
                if(parseInt(this.fromTime.hours+("0" + this.fromTime.minutes).slice(-2)) > parseInt(this.toTime.hours+("0" + this.toTime.minutes).slice(-2))){
                    this.isUploading = true
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Die Startzeit darf nicht größer als die Endzeit sein',
                    })
                    return                   
                }
                
                var value_start_picked  = ((this.fromTime.hours)  + ":" + ("0" + this.fromTime.minutes).slice(-2) + ":" + ("0" + this.fromTime.seconds).slice(-2)).split(':')
                var value_end_picked    = ((this.toTime.hours)  + ":" + ("0" + this.toTime.minutes).slice(-2) + ":" + ("0" + this.toTime.seconds).slice(-2)).split(':')
                var valued_start_picked_timestamp   = new Date().setHours(value_start_picked[0], value_start_picked[1], value_start_picked[2], 0)
                var valued_end_picked_timestamp     = new Date().setHours(value_end_picked[0], value_end_picked[1], value_end_picked[2], 0)
                
                if(valued_end_picked_timestamp - valued_start_picked_timestamp < 7200000 ){
                    this.isUploading = true
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Zeitfenster muss 2 Stunden auseinander liegen',
                    })
                    return     
                }
    
                if(("0" + value_start_picked[0]).slice(-2) +":"+value_start_picked[1]+":"+value_start_picked[2] > "17:00:00"){
                    this.isUploading = true
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Letzter Auftrag bis 17:00 möglich',
                    })
                    return
                }
                if(this.formatDateHuman(new Date(this.date_pickup))===this.formatDateHuman(new Date()) && Number(new Date()) + 60000 > valued_start_picked_timestamp ){
                    this.isUploading = true
                    var time = (new Date().getHours())  + ":" + (new Date().getMinutes() + 1);
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Die Startzeit darf nicht unter '+ time +' Uhr liegen',
                    })
                    return     
                }
                return true;
            },
            formatDateHuman(date){
                let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
                let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
                let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
                return `${day}.${month}.${year}`
            },
            formatDateComputer(date){
                let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
                let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
                let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
                return `${year}-${month}-${day} 00:00:00`
            },
            async loadPickupGo(hwb_param) {
                try {
                    await axios
                    .get('get-data-go', { 
                        params: {
                            hwb: hwb_param
                        }
                    })
                    .then((response) => {                       
                        //Avoid to edit a ordernumber is already canceled or released
                        if(response.data.body.status === 1){
                            this.isLoading      = false
                            this.go.fromTime    = response.data.body.timefrom
                            this.go.toTime      = response.data.body.timeto
                            var myDate          = new Date(response.data.body.date)
                            this.go.date        = response.data.body.date
                            this.date_pickup    = this.formatDateComputer(new Date(myDate.getFullYear(), myDate.getMonth(), myDate.getDate()))
                            this.go.packages    = response.data.body.cases
                            this.go.optional    = response.data.body.additional
                            this.name           = response.data.body.name
                            this.street         = response.data.body.street
                            this.zip            = response.data.body.zip
                            this.number         = response.data.body.number
                            this.city           = response.data.body.city
                        } else {
                            this.$router.replace({
                                name: 'pickup-list'
                            })
                        }
                    })
                } catch (e) {
                    this.isLoading = true
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Bitte versuchen Sie es später erneut',
                    })
                    this.$router.replace({
                        name: 'pickup-list'
                    })
                    
                }
            },
            async editDraft(){  
                this.isUploading = false
                if(this.validateFields()){
                    try {          
                        let fromTimeConverted   = (this.fromTime.hours)  + ":" + ("0" + this.toTime.minutes%60).slice(-2)
                        let toTimeConverted     = (this.toTime.hours)  + ":" + ("0" + this.toTime.minutes%60).slice(-2)    
                        await axios
                        .get('edit-go-draft', { 
                            params: {
                                packages    : this.go.packages,
                                datecustom  : this.formatDateHuman(new Date(new Date(this.date_pickup).getFullYear(), new Date(this.date_pickup).getMonth(), new Date(this.date_pickup).getDate())),
                                timeFrom    : fromTimeConverted,
                                timeTill    : toTimeConverted,
                                optional    : this.go.optional,
                                name        : this.name,
                                street      : this.street,
                                zip         : this.zip,
                                number      : this.number,
                                city        : this.city,
                                hwb         : this.$route.params.hwb,
                            }
                        })
                        .then((response) => {
                            this.hwbNumber = response.data.body.hwbNumber
                            this.isUploading = true
                            return this.loadCustomModal(fromTimeConverted, toTimeConverted)
                        })
                    } catch (e) {
                        if(e.response.status === 401){
                            this.$toast.error(`Ihre Sitzung ist beendet, bitte melden Sie sich erneut an`,{
                                position: "top-right",
                                duration: 7000,
                            });
                            this.deleteToken()
                            this.$router.replace({
                                name: 'home'
                            })
                            return
                        }
                        this.$swal({
                            icon: 'error',
                            title: 'Fehler',
                            text: 'Bitte versuchen Sie es später erneut',
                        })
                        this.isUploading = true
                    }
                }
            },
            async loadCustomModal(fromTimeConverted, toTimeConverted){
                this.koffer_go      = this.go.packages
                this.datum_go       = this.formatDateHuman(new Date(new Date(this.date_pickup).getFullYear(), new Date(this.date_pickup).getMonth(), new Date(this.date_pickup).getDate()))
                this.timefrom_go    = fromTimeConverted
                this.timeto_go      = toTimeConverted
                this.optional_go    = this.go.optional
                this.isUploading    = true
                this.isModal        = true
            },
            async cancelPickup(){
                this.isLoadingCancel = false
                try {
                    await axios
                    .get('cancel-go', { 
                        params: {
                            hwbNumber   : this.hwbNumber,
                            email       : this.userDataVue.username,
                        }
                    })
                    this.$router.replace({
                        name: 'abholung'
                    })
                    this.$toast.success(`Abholung wurde storniert`,{
                        position: "top-right",
                        duration: 4000,
                    });

                } catch (e) {
                    this.isUploading = true
                    this.isLoadingCancel = true
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Bitte versuchen Sie es später erneut',
                    })
                }
            },
            async sendDraftToGo(){
                this.isLoadingSend = false
                try {
                    let response = await axios
                    .get('release-go', { 
                        params: {
                            hwbNumber   : this.hwbNumber,
                            email       : this.userDataVue.username,
                        }
                    })
                    .then(() => {
                        //activate SET_AFTER_SENT_SUCCESS = true, to allow one time to show the page "GoSuccess"
                        this.setConfirmed()
                        this.$router.replace({ name: 'pickupgo-confirmed', params: { hwb: this.hwbNumber } })
                    })  
                    return response
                } catch (e) {
                    this.isUploading = true
                    this.isLoadingSend = true
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Bitte versuchen Sie es später erneut',
                    })
                }
            }
        },
        mounted() {
            this.loadPickupGo(this.url_data=this.$route.params.hwb)
        },
    }
</script>
<style lang="scss">
    .pickup-container{
    color: #17243d;
    font-family: "NotoSans-Light";
        h1{
            font-size: 2.1rem;
            font-weight: 100;
        }

        h4{
            font-size: 1.74rem;
            font-weight: 100;
        }
        h6{
            font-size: 1.125rem;
            margin-bottom: 6px;

        }

        hr{
            color: 2px solid #8b919e !important; 
        }
    }

    .button-edit{ 
        button{
            background-color: #81bb27;
            width: 100%;
            height: 3.5rem;
            color: #fff;
            border: none;
            border-radius: 0.3rem;
            outline: none;
            cursor: pointer;
            font-size: 1.2rem;
        }

        button:hover{
            background-color: #04833d;
        }
    }

    .button-edit-disabled{ 

        button{
            background-color: #C4BEBE;
            width: 100%;
            height: 3.5rem;
            color: #fff;
            border: none;
            border-radius: 0.3rem;
            outline: none;
            font-size: 1.2rem;
        }
    }

    .container{
        h6{
            font-family: "NotoSans-Medium";
        }
    }

    .dp-custom-cell {
        border-radius: 50%;
    }

    .custom-pick{
        div{
            .dp__input_wrap{
                input {
                    height: 60px;
                    text-align: center;
                    background: #ecedf0,
                }
            }
        }
    }

    .form-select{
        height: 60px;
        text-align: center;
        background: #ecedf0,
    }
    
    .custom-inp{
        height: 60px;
    }

    input[type="text"]{ 
        text-align: center;
        background: #ecedf0,
    }

  @media (max-width: 760px) {
    #insidebox{
        width: 370px;
        height: 600px;          
    }

    .insidebox-top{
        .insidebox-top-title {
            padding: 3.5vw;
        }
        .row-inside-top {
            .col-inside-top-left {
                text-align: center;
            }
        }
    }
  }
</style>