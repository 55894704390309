<template>
    <div class="container scandata-container">
        <form action="#" @submit.prevent="uploadClickButton">
            <br>
            <h1>Scandaten versenden</h1>
            <br>
            <h2>Dateiupload</h2>
            <h6>Dateien auswählen</h6>
            <image-uploader ref="filesarray"></image-uploader>
            <br><br>
            <div style="border-bottom: 1px solid black;"></div>
            <br><br>
            <h2>Stammdaten</h2>
            <h6>Behandelnder Arzt</h6>
            <div>
            <pickup-adress ref="praxis_adress"></pickup-adress>
            </div>
            <br>
            <div class="input-other">
                <input v-model="form.abweichender_behandler" placeholder="Abweichender Behandler">
            </div>
            <br><br>
            <div style="border-bottom: 1px solid black;"></div>
            <br>
            <h6>Patient in Behandlung</h6>
            <br>
            <div style="color: #009fe3;">
                <div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="gender" v-model="form.gender" id="check-woman" value="0">
                        <label class="form-check-label" for="check-women">Frau</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="gender" v-model="form.gender" id="check-man" value="1">
                        <label class="form-check-label" for="check-men">Herr</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="gender" v-model="form.gender" id="check-divers" value="2">
                        <label class="form-check-label" for="check-divers">Diverse</label>
                    </div>
                </div>
                <br>
                <div class="input-patient">
                    <input v-model="form.firstname" placeholder="Vorname"> <input v-model="form.lastname" placeholder="Nachname"><br>
                    <input v-model="form.xmlnummer" placeholder="XML-Nummer"><br>
                    <pickup-insurance ref="selected_insurance"></pickup-insurance>
                </div><br>
                <div class="form-check" style="color: #009fe3;">
                    <input class="form-check-input" type="checkbox" v-model="form.dentnet" id="dentnet">
                    <label class="form-check-label" for="dentnet">DentNet Versorgung</label>
                </div>
            </div>
            <br><br>
            <div style="border-bottom: 1px solid black;"></div>
            <br><br>
            <h6>KVA vorhanden</h6>
            <div style="color: #009fe3;">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="xml" v-model="form.xml" id="check-yes" value="0">
                    <label class="form-check-label" for="check-yes">Ja</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="xml" v-model="form.xml" id="check-no" value="1">
                    <label class="form-check-label" for="check-no">Nein</label>
                </div>
            </div>
            <br>
            <h6>Herstellungsort</h6>
            <div style="color: #009fe3;">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="position" v-model="form.position" id="check-foreign" value="0">
                    <label class="form-check-label" for="check-foreign">Ausland</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="position" v-model="form.position" id="check-inland" value="1">
                    <label class="form-check-label" for="check-inland">Inland</label>
                </div>
            </div>
            <br>
            <h6>Abrechnungsart</h6>
            <div style="color: #009fe3;">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="same" v-model="form.same" id="check-bill-1" value="0">
                    <label class="form-check-label" for="check-bill-1">Gleichartig</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="same" v-model="form.same" id="check-bill-2" value="1">
                    <label class="form-check-label" for="check-bill-2">Andersartig</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="same" v-model="form.same" id="check-bill-3" value="2">
                    <label class="form-check-label" for="check-bill-3">Regelversorgung</label>
                </div>
            </div>
            <br><br>
            <div style="border-bottom: 1px solid black;"></div>
            <br><br>
            <h2>Status</h2>
            <h6>Zahnschema</h6>
            <div class="schema-tooth">
                <div class="container1">
                    <div class="row">
                        <div class="col color-schema">
                        TP 
                        </div>
                        <input class="col" maxlength="10" v-model="form.tp1oben">
                        <input class="col" maxlength="10" v-model="form.tp2oben">
                        <input class="col" maxlength="10" v-model="form.tp3oben">
                        <input class="col" maxlength="10" v-model="form.tp4oben">
                        <input class="col" maxlength="10" v-model="form.tp5oben">
                        <input class="col" maxlength="10" v-model="form.tp6oben">
                        <input class="col" maxlength="10" v-model="form.tp7oben">
                        <input class="col" maxlength="10" v-model="form.tp8oben">
                    </div>
                    <div class="row">
                        <div class="col color-schema">
                        
                        </div>
                        <div class="col color-schema">
                        18 
                        </div>
                        <div class="col color-schema">
                        17 
                        </div>
                        <div class="col color-schema">
                        16
                        </div>
                        <div class="col color-schema">
                        15
                        </div>
                        <div class="col color-schema">
                        14
                        </div>
                        <div class="col color-schema">
                        13
                        </div>
                        <div class="col color-schema">
                        12
                        </div>
                        <div class="col color-schema">
                        11
                        </div>
                    </div>
                    <div class="row">
                        <div class="col color-schema">
                        
                        </div>
                        <div class="col color-schema">
                        48 
                        </div>
                        <div class="col color-schema">
                        47 
                        </div>
                        <div class="col color-schema">
                        46
                        </div>
                        <div class="col color-schema">
                        45
                        </div>
                        <div class="col color-schema">
                        44
                        </div>
                        <div class="col color-schema">
                        43
                        </div>
                        <div class="col color-schema">
                        42
                        </div>
                        <div class="col color-schema">
                        41
                        </div>
                    </div>
                    <div class="row">
                        <div class="col color-schema">
                        TP 
                        </div>
                        <input class="col" maxlength="10" v-model="form.tp1unten">
                        <input class="col" maxlength="10" v-model="form.tp2unten">
                        <input class="col" maxlength="10" v-model="form.tp3unten">
                        <input class="col" maxlength="10" v-model="form.tp4unten">
                        <input class="col" maxlength="10" v-model="form.tp5unten">
                        <input class="col" maxlength="10" v-model="form.tp6unten">
                        <input class="col" maxlength="10" v-model="form.tp7unten">
                        <input class="col" maxlength="10" v-model="form.tp8unten">
                    </div>
                </div>
                <br>
                <div class="container2">
                    <div class="row">
                        <div class="col color-schema">
                        TP 
                        </div>
                        <input class="col" maxlength="10" v-model="form.tp9oben">
                        <input class="col" maxlength="10" v-model="form.tp10oben">
                        <input class="col" maxlength="10" v-model="form.tp11oben">
                        <input class="col" maxlength="10" v-model="form.tp12oben">
                        <input class="col" maxlength="10" v-model="form.tp13oben">
                        <input class="col" maxlength="10" v-model="form.tp14oben">
                        <input class="col" maxlength="10" v-model="form.tp15oben">
                        <input class="col" maxlength="10" v-model="form.tp16oben">
                    </div>
                    <div class="row">
                        <div class="col color-schema">
                        
                        </div>
                        <div class="col color-schema">
                        21 
                        </div>
                        <div class="col color-schema">
                        22 
                        </div>
                        <div class="col color-schema">
                        23
                        </div>
                        <div class="col color-schema">
                        24
                        </div>
                        <div class="col color-schema">
                        25
                        </div>
                        <div class="col color-schema">
                        26
                        </div>
                        <div class="col color-schema">
                        27
                        </div>
                        <div class="col color-schema">
                        28
                        </div>
                    </div>
                    <div class="row">
                        <div class="col color-schema">
                        
                        </div>
                        <div class="col color-schema">
                        31 
                        </div>
                        <div class="col color-schema">
                        32 
                        </div>
                        <div class="col color-schema">
                        33
                        </div>
                        <div class="col color-schema">
                        34
                        </div>
                        <div class="col color-schema">
                        35
                        </div>
                        <div class="col color-schema">
                        36
                        </div>
                        <div class="col color-schema">
                        37
                        </div>
                        <div class="col color-schema">
                        38
                        </div>
                    </div>
                    <div class="row">
                        <div class="col color-schema">
                        TP 
                        </div>
                        <input class="col" maxlength="10" v-model="form.tp9unten">
                        <input class="col" maxlength="10" v-model="form.tp10unten">
                        <input class="col" maxlength="10" v-model="form.tp11unten">
                        <input class="col" maxlength="10" v-model="form.tp12unten">
                        <input class="col" maxlength="10" v-model="form.tp13unten">
                        <input class="col" maxlength="10" v-model="form.tp14unten">
                        <input class="col" maxlength="10" v-model="form.tp15unten">
                        <input class="col" maxlength="10" v-model="form.tp16unten">
                    </div>
                </div>
            </div><br>
            <div class="row">
                <div class="col">
                    <div class="form-check" style="color: #009fe3;">
                        <input class="form-check-input" type="checkbox" v-model="form.interimsprothese" id="interimsprothese">
                        <label class="form-check-label" for="interimsprothese">Interimsprothese</label>
                    </div>
                </div>
                <div class="col">
                    <div class="form-check" style="color: #009fe3;">
                        <input class="form-check-input" type="checkbox" v-model="form.kronen_verblockt" id="kronen_verblockt">
                        <label class="form-check-label" for="kronen_verblockt">Kronen verblockt</label>
                    </div>
                </div>
            </div><br>
            <h6>Zahnfarbe</h6>
            <div class="input-other">
                <input placeholder="Vita" id="zahnfarbe" v-model="form.zahnfarbe">
            </div>
            <br><br>
            <div style="border-bottom: 1px solid black;"></div>
            <br>
            <h2>Ausführung</h2>
            <br>
            <div class="container">
                <div class="row">
                    <div class="col-sm max-select-radios">
                        <p>Kronen und Vollverblendung</p>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="verblendung" v-model="form.verblendung" @change="onChangeDisable()" id="mat-radio-10" value="0">
                            <label class="form-check-label" for="mat-radio-10">Vollverblendung ohne Metallrand</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="verblendung" v-model="form.verblendung" @change="onChangeDisable()" id="mat-radio-11" value="1">
                            <label class="form-check-label" for="mat-radio-11">Vollverblendung mit Metallrand</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="verblendung" v-model="form.verblendung" @change="onChange()" id="metallrand_zirkulaer" value="2">
                            <label class="form-check-label" for="metallrand_zirkulaer">Metallrand zirkulär</label>
                                <div class="">
                                <input id="mm" v-model="form.mm" placeholder="Millimeter" disabled>
                            </div>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="verblendung" v-model="form.verblendung" @change="onChangeDisable()" id="mat-radio-9" value="3">
                            <label class="form-check-label" for="mat-radio-9">Vestibuläre Verblendung</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="verblendung" v-model="form.verblendung" @change="onChangeDisable()" id="mat-radio-13" value="4">
                            <label class="form-check-label" for="mat-radio-13">Keramik</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="verblendung" v-model="form.verblendung" @change="onChangeDisable()" id="mat-radio-14" value="5">
                            <label class="form-check-label" for="mat-radio-14">Komposite</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="verblendung" v-model="form.verblendung" @change="onChangeDisable()" id="mat-radio-15" value="6">
                            <label class="form-check-label" for="mat-radio-15">Keramikschulter</label>
                        </div>
                    </div>
                    <div class="col-sm max-select-radios">
                        <p>Material</p>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="legierung" v-model="form.legierung" id="mat-radio-65" value="0">
                            <label class="form-check-label" for="mat-radio-65">NEM</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="legierung" v-model="form.legierung" id="mat-radio-66" value="1">
                            <label class="form-check-label" for="mat-radio-66">Sattgelb: Bio Porta G, P4</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="legierung" v-model="form.legierung" id="mat-radio-67" value="2">
                            <label class="form-check-label" for="mat-radio-67">Gelb: Porta Maximum</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="legierung" v-model="form.legierung" id="mat-radio-68" value="3">
                            <label class="form-check-label" for="mat-radio-68">Weiß: Silber-Palladium (Simidur S1S)</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="legierung" v-model="form.legierung" id="mat-radio-69" value="4">
                            <label class="form-check-label" for="mat-radio-69">Weiß: Porta-SMK</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="legierung" v-model="form.legierung" id="mat-radio-70" value="5">
                            <label class="form-check-label" for="mat-radio-70">Blassgelb: Eurogold Supra</label>
                        </div>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-sm max-select-radios">
                        <p>Brückengliedauflage</p>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="auflage" v-model="form.auflage" id="mat-radio-110" value="0">
                            <label class="form-check-label" for="mat-radio-110">Sattel</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="auflage" v-model="form.auflage" id="mat-radio-111" value="1">
                            <label class="form-check-label" for="mat-radio-111">Pontic</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="auflage" v-model="form.auflage" id="mat-radio-112" value="2">
                            <label class="form-check-label" for="mat-radio-112">Tangential</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="auflage" v-model="form.auflage" id="mat-radio-113" value="3">
                            <label class="form-check-label" for="mat-radio-113">Schwebe</label>
                        </div>
                    </div>
                    <div class="col-sm max-select-radios">
                        <p>Zirkonversorgung</p>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="versorgung" v-model="form.versorgung" id="mat-radio-165" value="0">
                            <label class="form-check-label" for="mat-radio-165">Zirkonkrone verblendet</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="versorgung" v-model="form.versorgung" id="mat-radio-166" value="1">
                            <label class="form-check-label" for="mat-radio-166"> Vollzirkonkrone bemalt </label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="versorgung" v-model="form.versorgung" id="mat-radio-167" value="2">
                            <label class="form-check-label" for="mat-radio-167"> Hybridkrone/-brücke </label>
                        </div>
                    </div>
                </div>
            </div>
            <br><br>
            <div style="border-bottom: 1px solid black;"></div>
            <br>
            <h2>Sonderausführung</h2>
            <br>
            <div class="container">
                <div class="row">
                    <div class="col-sm max-select-radios">
                        <p>Kronen</p>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="form.ikronedeutschland" id="mat-checkbox-21">
                            <label class="form-check-label" for="mat-checkbox-21">
                                iKrone® Klassik Made in Germany (Monolithisch, monochrome)
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="form.ikronepremiumdeutschland" id="mat-checkbox-22">
                            <label class="form-check-label" for="mat-checkbox-22">
                                iKrone® Premium Made in Germany (Monolithisch, Farb-/Transparenzverlauf)
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="form.empress" id="mat-checkbox-23">
                            <label class="form-check-label" for="mat-checkbox-23">
                                Empress
                            </label>
                        </div><br>
                    </div>
                    <div class="col-sm max-select-radios">
                        <p>Prothesen</p>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="form.iflex" id="mat-checkbox-24">
                            <label class="form-check-label" for="mat-checkbox-24">
                                iFlex® 
                            </label><br>
                            <label>
                                (Flexible, antiallergen)
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="form.reiseprothese" id="mat-checkbox-26">
                            <label class="form-check-label" for="mat-checkbox-26">
                                iFlex® Premium 
                            </label><br>
                            <label>
                                (Flexible, reparable)
                            </label>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm max-select-radios">
                    <p>Teleskope</p>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="form.iteleskop" id="mat-checkbox-27">
                            <label class="form-check-label" for="mat-checkbox-27">
                                iTeleskop® 2.0 (Gold-Platin-Veredelung)
                            </label>
                        </div><br>
                    </div>
                    <div class="col-sm max-select-radios">
                    <p>Zahnschienen</p>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="form.istraightzahnkorrektur" id="mat-checkbox-29">
                            <label class="form-check-label" for="mat-checkbox-29">
                                iStraight® (Clear Aligner Therapie)
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="form.isilentschnarchschiene" id="mat-checkbox-30">
                            <label class="form-check-label" for="mat-checkbox-30">
                                iSilent® (Unterkieferprotrusionsschiene)
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <br><br>
            <div style="border-bottom: 1px solid black;"></div>
            <br>
            <h2>Implantate</h2>
            <br>
            <div class="container">
                <div class="row">
                    <div class="col-sm max-select-radios">
                    <p>Abutment</p>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="form.abutmentmetall" id="mat-checkbox-31">
                            <label class="form-check-label" for="mat-checkbox-31">
                                indiv. Abutment Metall
                            </label>
                        </div>
                        <br>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="form.abutmentzirkon" id="mat-checkbox-32">
                            <label class="form-check-label" for="mat-checkbox-32">
                                indiv. Abutment Zirkon
                            </label>
                        </div><br>
                    </div>
                    <div class="col-sm max-select-radios">
                    <p>Implantatsystem</p>
                        <div class="input-other-implantate">
                            <input v-model="form.angabe_implantatensystem" placeholder="Angabe Implantantsystem">
                        </div>
                        <div class="input-other-implantate">
                            <input v-model="form.durchmesser_millimeter" placeholder="Durchmesser Milimiter">
                        </div>
                    </div>
                </div>
            </div>
            <br><br>
            <div style="border-bottom: 1px solid black;"></div>
            <br>
            <h2>Prothesen</h2>
            <br>
            <div class="container">
                <div class="row">
                    <div class="col-sm max-select-radios">
                    <p>Geschiebetechnik</p>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="geschiebe" v-model="form.geschiebe" id="mat-radio-81" value="0">
                            <label class="form-check-label" for="mat-radio-81">Duolock</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="geschiebe" v-model="form.geschiebe" id="mat-radio-82" value="1">
                            <label class="form-check-label" for="mat-radio-82">Variosoft (Kunststoffmatrize)</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="geschiebe" v-model="form.geschiebe" id="mat-radio-83" value="2">
                            <label class="form-check-label" for="mat-radio-83"> Teilungsgeschiebe </label>
                        </div><br><br>
                    </div>
                    <div class="col-sm max-select-radios">
                    <p>Riegeltechnik</p>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="technik" v-model="form.technik" id="mat-radio-181" value="0">
                            <label class="form-check-label" for="mat-radio-181">MK1 Riegel</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="technik" v-model="form.technik" id="mat-radio-182" value="1">
                            <label class="form-check-label" for="mat-radio-182">Schwenk-Riegel</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="technik" v-model="form.technik" id="mat-radio-183" value="2">
                            <label class="form-check-label" for="mat-radio-183">Robolock</label>
                        </div><br>
                    </div>
                </div><br>
                <div class="row">
                    <div class="col-sm max-select-radios">
                    <p>Modellgussdesign</p>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="design" v-model="form.design" id="mat-radio-89" value="0">
                            <label class="form-check-label" for="mat-radio-89">Hufeisenform</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="design" v-model="form.design" id="mat-radio-90" value="1">
                            <label class="form-check-label" for="mat-radio-90">Transversalverbinder </label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="design" v-model="form.design" id="mat-radio-91" value="2">
                            <label class="form-check-label" for="mat-radio-91"> skelettierte Form </label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="design" v-model="form.design" id="mat-radio-92" value="3">
                            <label class="form-check-label" for="mat-radio-92">  ohne Modellguss   </label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="design" v-model="form.design" id="mat-radio-1193" value="4">
                            <label class="form-check-label" for="mat-radio-1193"> geg. Ret. auf Kieferkamm </label>
                        </div><br><br>
                    </div>
                    <div class="col-sm max-select-radios">
                    <p>Coverdenture</p>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="coverdenture" v-model="form.coverdenture" id="mat-radio-94" value="0">
                            <label class="form-check-label" for="mat-radio-94">Kunststoffbasis</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="coverdenture" v-model="form.coverdenture" id="mat-radio-95" value="1">
                            <label class="form-check-label" for="mat-radio-95">Retentionsgitter</label>
                        </div><br>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="coverdenture" v-model="form.coverdenture" id="mat-radio-96" value="2">
                            <label class="form-check-label" for="mat-radio-96">Vollplatte Metall</label>
                        </div><br>
                    </div>
                </div>
            </div>
            <br><br>
            <div style="border-bottom: 1px solid black;"></div>
            <br>
            <h2>Terminierung bis 12:00 Uhr</h2>
            <br>
            <div class="container">
                <div class="row">
                    <div class="col-sm">
                        <p><strong>Primär, Löffel und Biss</strong></p>
                        <Datepicker uid="loeffel_datum" v-model="form.loeffel_datum" :minDate="new Date()" weekStart="1" :disabledWeekDays="[6, 0]" :enableTimePicker="false" class="dp-custom-input dp-custom-cell" placeholder="Datum auswählen" autoApply></Datepicker>
                    </div>
                    <div class="col-sm">
                        <p><strong>Gerüstanprobe</strong></p>
                        <Datepicker uid="geruestanprobe_datum" v-model="form.geruestanprobe_datum" :minDate="new Date()" weekStart="1" :disabledWeekDays="[6, 0]" :enableTimePicker="false" class="dp-custom-input dp-custom-cell" placeholder="Datum auswählen" autoApply></Datepicker>                        
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm">
                        <p><strong>Gesamtanprobe</strong></p>
                        <Datepicker uid="gesamtanprobe_datum" v-model="form.gesamtanprobe_datum" :minDate="new Date()" weekStart="1" :disabledWeekDays="[6, 0]" :enableTimePicker="false" class="dp-custom-input dp-custom-cell" placeholder="Datum auswählen" autoApply></Datepicker>
                    </div>
                    <div class="col-sm">
                        <p><strong>Fertigstellung</strong></p>
                        <Datepicker uid="fertigstellung_datum" v-model="form.fertigstellung_datum" :minDate="new Date()" weekStart="1" :disabledWeekDays="[6, 0]" :enableTimePicker="false" class="dp-custom-input dp-custom-cell" placeholder="Datum auswählen" autoApply></Datepicker>
                    </div>
                </div>
            </div>
            <br><br>
            <div style="border-bottom: 1px solid black;"></div>
            <br>
            <h2>Notizen</h2>
            <div class="container">
                <p>Besonderheiten</p>
                <div class="row">
                    <div class="col">
                        <div class="input-other-implantate">
                            <input v-model="form.notiz" placeholder="Ihre Notizen">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="form.rueckruf" id="mat-checkbox-rueckruf">
                            <label class="form-check-label" for="mat-checkbox-rueckruf" style="color: #009fe3;">
                                Bitte um Rückruf
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div style="border-bottom: 1px solid black;"></div>
            <br>
            <div v-bind:id="'upload_btn_id'" class="button-upload" v-if="isUploading">
                <button type="submit"><strong>Scandaten versenden</strong></button>
            </div>
            <progress-bar v-if="!isUploading" :progress="progress"></progress-bar><br>
            <p>Der Auftrag wird zu unseren <a target='_blank' href="/#/agb" style="color: #009fe3;">Geschäftsbedingungen</a> ausgeführt.</p>
        </form>
    </div>
</template>
<script>
    import PickupInsurance from '@/components/Menu/Scandaten/PickupInsurance'
    import PickupAdress from '@/components/Menu/Scandaten/PickupAdress'
    import ProgressBar from '@/components/Menu/Scandaten/ProgressBar.vue'
    import ImageUploader from '@/components/Menu/Scandaten/ImageUploader.vue'
    import Datepicker from 'vue3-date-time-picker'
    import 'vue3-date-time-picker/dist/main.css'
    import { mapActions } from 'vuex'
    import axios from "axios"
    import { ref } from 'vue';
    export default {
        name: 'Scandata',
        data: () => ({
            progress: 0,
            isUploading: true,
            isError: false,
            form: {
                gender: '',
                xml: '',
                position: '',
                same: '',
                patientname: '',
                abweichender_behandler: '',
                xmlnummer: '',
                krankenkasse: '',
                dentnet: false,
                tp1oben: '',
                tp2oben: '',
                tp3oben: '',
                tp4oben: '',
                tp5oben: '',
                tp6oben: '',
                tp7oben: '',
                tp8oben: '',
                tp9oben: '',
                tp10oben: '',
                tp11oben: '',
                tp12oben: '',
                tp13oben: '',
                tp14oben: '',
                tp15oben: '',
                tp16oben: '',
                tp1unten: '',
                tp2unten: '',
                tp3unten: '',
                tp4unten: '',
                tp5unten: '',
                tp6unten: '',
                tp7unten: '',
                tp8unten: '',
                tp9unten: '',
                tp10unten: '',
                tp11unten: '',
                tp12unten: '',
                tp13unten: '',
                tp14unten: '',
                tp15unten: '',
                tp16unten: '',
                interimsprothese: false,
                kronen_verblockt: false,
                zahnfarbe: '',
                verblendung: '',
                mm: '',
                legierung: '',
                auflage: '',
                versorgung: '',
                ikronedeutschland: false,
                ikronepremiumdeutschland: false,
                empress: false,
                iflex: false,
                reiseprothese: false,
                // geruestcadcam: false,
                iteleskop: false,
                iteleskoppremium: false,
                istraightzahnkorrektur: false,
                isilentschnarchschiene: false,
                abutmentmetall: false,
                abutmentzirkon: false,
                angabe_implantatensystem: '',
                durchmesser_millimeter: '',
                geschiebe: '',
                technik: '',
                design: '',
                coverdenture: '',
                loeffel_datum: '',
                geruestanprobe_datum: '',
                gesamtanprobe_datum: '',
                fertigstellung_datum: '',
                notiz: '',
                rueckruf: false,
                praxis: '',
            }
        }),
        setup() {
            const loeffel_datum = ref();
            const geruestanprobe_datum = ref();
            const gesamtanprobe_datum = ref();
            const fertigstellung_datum = ref();
            return {
                loeffel_datum,
                geruestanprobe_datum,
                gesamtanprobe_datum,
                fertigstellung_datum,
            }
        },
        components: {
            PickupAdress,
            ProgressBar,
            ImageUploader,
            Datepicker,
            PickupInsurance,
        },
        methods: {
            ...mapActions({
                setAfter: 'scandata/setAfterSentSucess',
                deleteToken: 'auth/removeTokenUser',
            }),
            onChange() {
                document.getElementById("mm").disabled = false
            },
            onChangeDisable(){
                document.getElementById('mm').value = ""
                document.getElementById('mm').placeholder = "Millimeter"
                document.getElementById("mm").disabled = true
            },
            formularReady(){
                //Import insurance name from component PickupInsurance
                if(this.form.krankenkasse === "Krankenkasse auswählen"){
                    this.form.krankenkasse = ""
                }
                this.form.krankenkasse = this.$refs.selected_insurance.krankenkasse_selected

                // Hide Upload Button and show Loading Bar after click
                this.isUploading = false

                //Concatenate firstname and lastname into patientname
                if(this.form.firstname != null && this.form.lastname != null){
                    this.form.patientname = this.form.firstname + ' ' +this.form.lastname
                } else if(this.form.firstname != null){
                    this.form.patientname = this.form.firstname 
                } else if(this.form.lastname != null){
                    this.form.patientname = this.form.lastname
                } else if (this.form.firstname == null && this.form.lastname == null){
                    this.form.patientname = "----"
                }

                //Concatenate praxis data
                this.form.praxis = this.$refs.praxis_adress.praxis.praxis + "\n" + this.$refs.praxis_adress.praxis.firstname 
                                    + " " + this.$refs.praxis_adress.praxis.lastname + "\n" + 
                                    this.$refs.praxis_adress.praxis.street + "\n" +  this.$refs.praxis_adress.praxis.zip + " " +
                                    this.$refs.praxis_adress.praxis.city
            },
            async uploadClickButton(){
                this.formularReady()
                if(this.$refs.filesarray.files.length === 0){
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Sie haben keine Dateien ausgewählt',
                    })
                    this.isUploading = true
                    return
                }

                try {
                    let response = await axios.post('/scandata-save', this.form, {
                        params: {
                            name : this.$refs.praxis_adress.praxis.firstname + " " + this.$refs.praxis_adress.praxis.lastname,
                            plz  : this.$refs.praxis_adress.praxis.zip,
                            city : this.$refs.praxis_adress.praxis.city,
                        },
                    })
                    return this.sendImage(this.$refs.filesarray.files, response.data.last_insert_id) 
                } catch (e) {
                    if(e.response.status === 401){
                        this.$toast.error(`Ihre Sitzung ist beendet, bitte melden Sie sich erneut an`,{
                            position: "top-right",
                            duration: 7000,
                        });
                        this.deleteToken()
                            this.$router.replace({
                                name: 'home'
                            })
                        return
                    }
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Bitte versuchen Sie es später erneut',
                    })
                    this.isUploading = true
                }
            },
            async sendImage(files_import, last_insert_id){
                const formData = new FormData()
                files_import.forEach(file => {
                    formData.append('images[]', file, file.name)
                })

                try {
                    let response = await axios.post('/upload-save', formData, {
                        params: {
                            id_temp: last_insert_id
                        },
                        onUploadProgress: e => {
                            if(e.lengthComputable){
                                this.progress = (e.loaded / e.total) * 100
                                // console.log(this.progress)
                            }
                        }
                    })
                    .then(() => {
                        //activate SET_AFTER_SENT_SUCCESS = true, to allow one time to show the page "UploadSuccess"
                        this.setAfter()

                        // Show Upload Button and hide Loading Bar after click
                        setTimeout(() => {
                            this.$router.replace('/confirmed/scandaten')
                        }, 1000);
                    })          
                    return response
                } catch (e) {
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Bitte versuchen Sie es später erneut',
                    })
                    this.isUploading = true
                }
            },
        }
    }
</script>
<style lang="scss" scoped>
    .scandata-container{
    color: #17243d;
    font-family: "NotoSans-Medium";
        h1{
            font-size: 2.1rem;
            font-weight: 100;
            font-family: "NotoSans-Light";
        }

        h2{
            font-size: 1.74rem;
            font-weight: 100;
            margin-bottom: 20px;
            font-family: "NotoSans-Light";
        }
        h6{
            font-size: 1.125rem;
            margin-bottom: 16px;
            font-family: "NotoSans-Medium";
            font-weight: 300;
        }

        hr{
            color: 2px solid #8b919e !important; 
        }

        .form-check-input[type=radio] {
            border-color: #009fe3;
        }

        .form-check-input[type=checkbox] {
            border-color: #009fe3;
        }

        .schema-tooth .col {

            margin: 0.1px;
        }

        input{
            color: #8b919e;
            font-size: 1.125rem;
        }

        input:focus{
            border: 1px solid red !important;
        }
    }

    .input-other input{
        width: 100%;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #696969;
    }

    .input-other-implantate input{
        width: 100%;
        padding: 4px;
        border-radius: 5px;
        border: 1px solid #696969;
        margin-bottom: 12px;
    }

    .input-patient input{
        width: 100%;
        padding: 15px;
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid #696969;
    }

    .schema-tooth .col{
        border: 1px solid #696969;
        margin:1px;
        text-align: center;
        border: 0.5px solid #8b919e;
    }

    .color-schema{
        background: #ecedf0;
    }

    .max-select-radios{
        text-align: left;
        font-size: 15px;
        color: #009fe3;
        font-family: "NotoSans-Medium";
    }

    .max-select-radios p{ 
        color: black;
    }

    .button-upload{ 

        button{
            background-color: #81bb27;
            width: 100%;
            height: 3.5rem;
            color: #fff;
            border: none;
            border-radius: 0.3rem;
            outline: none;
            cursor: pointer;
            font-size: 1.2rem;
        }

        button:hover{
            background-color: #04833d;
        }
    }
    /*END DRAG AND DROP*/

    .dp-custom-input {
    color: #1976d2;

    &:hover {
        border-color: #1976d2;
        box-shadow: 0 0 20px #1976d2;
    }
    }

    .dp-custom-cell {
    border-radius: 50%;
    }
</style>