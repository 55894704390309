<template>
    <div class="container container-custom">
        <div class="container-interior-dashboard">
            <div class="row">
                <Menu_Left />
                <div  class="col">
                  <Profile />
                </div>
                <div class="col-sm-3 menu-info-title">
                  <Menu_Right />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Menu_Left from '@/components/Menu_Left'
  import Profile from '@/components/Profile/Profile'
  import Menu_Right from '@/components/Menu_Right'
  export default {
    components: {
      Menu_Left,
      Profile,
      Menu_Right
    },
  }
</script>