<template>
    <div class="container container-time">
        <br>
        <h1>Lieferzeiten berechnen</h1>
        <br>
        <h2>Lieferzeiten</h2>
        <br>
        <div class="time-style">
            <div class="time-style-inside">
                <div class="row-time">
                    <p>Abholdatum</p>
                    <Datepicker hideOffsetDates  uid="date" :format="format" v-model="date_actual" :minDate="new Date()" weekStart="1" :disabledWeekDays="[6, 0]" :enableTimePicker="false" class="dp-custom-input dp-custom-cell custom-pick" placeholder="Datum auswählen" autoApply></Datepicker>
                </div>
                <div class="row-time">
                    <p>Produkt auswählen</p>
                    <select id="dynamicSelect" class="form-select" aria-label="Default select example" v-model="type_selected" @change="sendIdOnChange($event)">
                        <option selected value="non">Produkt auswählen</option>  
                    </select>
                </div>
                <div class="row-time button-upload">
                    <button v-on:click="clickCalculate" type="button" class="btn">Berechnen</button>
                </div>
                <div class="row-time">
                    <p>Liefertermin</p>
                    <input style="text-align: center; height: 60px; background-color: #ecedf0; font-family: 'NotoSans-Medium';" class="form-control" disabled :value="nameString">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Datepicker from 'vue3-date-time-picker';
    import 'vue3-date-time-picker/dist/main.css'
    import axios from "axios";
    import { ref } from 'vue';
    export default {
        name: 'Time',
        data: () => ({
            holidayInternational: [],
            holidayNational: [],
            typesDelivery: [],
            type_selected: '',
            id__from_select: '',
            day_estimated_input: '',
        }),
        setup() {
            const date_actual = ref(new Date());
            const format = (date_actual) => {
                const day = date_actual.getDate().toString().padStart(2, "0");
                const month = (date_actual.getMonth() + 1).toString().padStart(2, "0");
                const year = date_actual.getFullYear();
                return `${day}.${month}.${year}`;
            }

            return {
                date_actual,
                format,
            }
        },
        components: {
            Datepicker,
        }, 
        methods: {
            //Save id on variable id__from_select
            sendIdOnChange(event){
                this.id__from_select = event.target.value
            },

            //Get Integer of the day of the week: Monday = 0 , Tuesday = 1...
            getDayOfTheWeek(value){
                const d = value
                return d.getDay()-1
            },

            formatDateHuman(date){
                let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
                let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
                let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
                return `${day}.${month}.${year}`
            },

            checkInternationalOrNational(){
                if(this.typesDelivery[this.id__from_select].isChina){
                    return true
                }
                return false
            },

            //After click will calculate the date
            clickCalculate() {
                if(this.date_actual == null){
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Datum muss ausgewählt werden',
                    })
                    return
                }
                if(this.type_selected == "" || document.getElementById("dynamicSelect").value == "non"){
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Produkt muss ausgewählt werden',
                    })
                    return
                }

                //Calculate only business days from day picked from user
                const date_estimated = new Date(this.date_actual)  //---> Date of the pickup day from user
                const numToAdd = this.typesDelivery[this.id__from_select].days[this.getDayOfTheWeek(this.date_actual)]  //---> Get the number of days from monday - friday
                for (let i = 1; i <= numToAdd; i++) {
                    date_estimated.setDate(date_estimated.getDate() + 1)
                    //If date is saturday will jump 2 days
                    if (date_estimated.getDay() === 6) {
                        date_estimated.setDate(date_estimated.getDate() + 2)
                    }
                    //Iteration from Holidays between National / International
                    if(this.checkInternationalOrNational()){
                        for(let y = 0; y < this.holidayInternational.length; y++){
                            //It will first convert the date to the format dd/mm/yyyy and compare. If the are the same will jump one day
                            if(this.formatDateHuman(Date.parse(this.holidayInternational[y].date)) === this.formatDateHuman(Date.parse(date_estimated))){
                                date_estimated.setDate(date_estimated.getDate() + 1)
                                //If date is saturday
                                if (date_estimated.getDay() === 6) {
                                    date_estimated.setDate(date_estimated.getDate() + 2)
                                }
                            }
                        }
                    } else if(!this.checkInternationalOrNational()) {
                        for(let y = 0; y < this.holidayNational.length; y++){
                            //It will first convert the date to the format dd/mm/yyyy and compare. If the are the same will jump one day
                            if(this.formatDateHuman(Date.parse(this.holidayNational[y].date)) === this.formatDateHuman(Date.parse(date_estimated))){
                                date_estimated.setDate(date_estimated.getDate() + 1)
                                //If date is saturday
                                if (date_estimated.getDay() === 6) {
                                    date_estimated.setDate(date_estimated.getDate() + 2)
                                }
                            }
                        }
                    }
                }
                this.day_estimated_input = this.formatDateHuman(date_estimated)
            },

            async loadJsonApi(){
                try{
                    let response = await axios.get('get-holidays')
                    return this.populateSelect(response.data.data.payload)                
                } catch (e) {
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Produkte wurden nicht gelanden. Bitte versuchen Sie es später erneut',
                    })
                }
            },

            populateSelect(json_response){
                var selectList = document.getElementById("dynamicSelect")
                
                //Save the array into typesDelivery array
                this.typesDelivery = json_response.deliveryTypes

                //Save the array into holidayInternational array
                this.holidayInternational = json_response.chineseHolidays

                //Save the array into holidayNational array
                this.holidayNational = json_response.holidays
                
                //Create and append the options
                for (var i = 0; i < json_response.deliveryTypes.length; i++){
                    var option_dynamic = document.createElement("option")
                    option_dynamic.value = i
                    option_dynamic.text = json_response.deliveryTypes[i].name
                    selectList.appendChild(option_dynamic)
                }
            }
        },
        computed:{
            nameString(){
                return `${this.day_estimated_input}`
            }
        },
        mounted(){
            this.loadJsonApi()
        }
    }
</script>
<style lang="scss">
    .container-time{
    color: #17243d;
    font-family: "NotoSans-Light";
        h1{
            font-size: 2.1rem;
            font-weight: 100;
        }

        h2{
            font-size: 1.74rem;
            font-weight: 100;
        }

        hr{
            color: 2px solid #8b919e !important; 
        }
    }

    .time-style{
        background: #f8fafc;
    }

    .time-style {
        p {
            color: #17243D;
        }

        .row-time{
            padding: 10px;
        
            input{
                width: 100%;
                height: 35px;
            }

        }
    }

    .time-style-inside{
        font-family: "NotoSans-Regular";
        color: #17243d;
        padding: 1.5%;
        p{
            color: #17243D;
            margin-bottom: 0.5rem;
            font-size: 1.1rem;
            font-weight: 100;
            font-weight: bold;
        }

        .form-select{
            border: 1px solid #8b919e;
            color: #8b919e!important;
            font-size: 1.1rem;
            font-family: "NotoSans-Medium";
        }

        input{
            border: 1px solid #8b919e;
            color: #8b919e!important;
            font-size: 1.1rem;
            font-family: "NotoSans-Medium";
        }
    }

    .custom-pick{
        div{
            .dp__input_wrap{
                input {
                    border: 1px solid #8b919e;
                    height: 60px;
                    text-align: center;
                    background: #ecedf0;      
                }
            }
        }
    }

    .dp-custom-input {
        color: #1976d2 !important;
    }

    .button-upload{ 

        button{
            background-color: #81bb27;
            width: 100%;
            height: 3.5rem;
            color: #fff;
            border: none;
            border-radius: 0.3rem;
            outline: none;
            cursor: pointer;
            font-size: 1.1rem;
            font-family: "NotoSans-Medium";
        }

        button:hover{
            background-color: #04833d;
        }
    }
</style>