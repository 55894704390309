<template>
    <div class="container ikrone-text info-container-dashboard">
        <h1>iTeleskop® 2.0 - Tragekomfort auf Goldniveau</h1>
        <br>
        <div class="img-infos">
            <img src="@/assets/images/iTeleskop20-info-onlineportal.jpg">
        </div>
        <br>
        <p class="subtitle-iteleskop">
            Die spezielle Veredelung des iTeleskop® 2.0 sorgt für eine herausragende
            Passung und Friktion. Das tägliche Ein- und Ausgliedern gelingt sanft und
            schont das Parodontium.        
        </p>
        <br>
        <h2>Das iTeleskop® 2.0</h2>
        <br>
        <p>
            Mit dem verbesserten iTeleskop® 2.0
            versorgen Sie Patienten auf höchstem
            Niveau. Alle Eigenschaften, die schon
            seinen Vorgänger erfolgreich machten,
            sind ohne Teflonkäppchen möglich. Dadurch
            wurde der Platzbedarf reduziert.        
        </p>
        <br>
        <h2>Gold-Platin-Veredelung</h2>
        <br>
        <p>
            Anteile von Gold und Platin sorgen in
            dieser speziellen Legierung auf Kobalt-
            Chrom-Basis für vergleichbare Trage-
            und Materialeigenschaften wie
            eine Goldversorgung. Im Vergleich zur
            Standardlegierung hat das iTeleskop® 2.0
            verbesserte Eigenschaften im Bereich
            Passung, Friktion und Lebensdauer. Das
            Ein- und Ausgliedern erfolgt schonender
            für das Parodontium. Preisbeispiel: Zwei
            Teleskope mit Modellguss zum Ersatz von
            acht Zähnen: 1.206 Euro ggü. 1.146 Euro
            für das Standardmaterial. Das iTeleskop® 2.0 ist die bevorzugte Wahl bei konventionellen
            Teleskopversorgungen.
        </p>
        <br>
        <div class="title-table">IMEX® iTeleskop® 2.0</div>
        <div class="body-table-custom container">
            <div class="row">
                <div class="col"> 
                    <div>
                        Nickelfreie Edelmetall-Legierung
                    </div>
                </div>
            </div>
            <div class="row body-table-custom-drip">
                <div class="col"> 
                    <div>
                        Schonendes Ein- und Ausgliedern
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col"> 
                    <div>
                        Herausragende Passung und Friktion
                    </div>
                </div>
            </div>
            <div class="row body-table-custom-drip">
                <div class="col"> 
                    <div>
                        Nur 30 Euro Aufpreis ggü. Standardmaterial
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col"> 
                    <div>
                        Kostenlose Reiseprothese inklusive
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .title-table{
        color: #fff;
        background-color: #1c3e8e;
        padding: 7px;
        font-family: "NotoSans-Medium";
    }

    .subtitle-iteleskop{
        color: #1c3e8e;
        font-family: "NotoSans-Medium";
        font-weight: 100;
    }

    h2 {
        color: #1c3e8e;
        font-family: "NotoSans-Medium";
        font-size: 1.74rem;
        font-weight: 100;
    }

    .body-table-custom{
        color: #1c3e8e;
        padding: 12px;
        .body-table-custom-drip{
            background-color: #f4f5f9;
        }
        
        .row{
            padding: 7px;
        }
    }
</style>

<script>
export default {
    name: 'Ikrone',
}
</script>