<template>
<br>
<div class="container">
    <p>Ansprechpartner</p>
    <div class="menu-info"> 
        <div class="row">
            <div class="col-4 align-self-center align-self-center-custom">
                <img src="../assets/images/imex-contact.svg" height="60">
                
            </div>
            <div class="col-8">
                <div class="row menu-info-text-1">
                    <p>Bei Rückfragen erreichen Sie uns unter</p>
                </div>
                <div class="row menu-info-text-2">
                    <p><a href="tel:080090808080">Telefon 0800 - 90808080</a></p>
                    <!-- <p>Telefon 0800 - 90808080</p>   -->
                </div>
            </div>
        </div>
    </div>
    <br>
</div>
</template>


