<template>
    <div class="uploader"
        @dragenter="OnDragEnter"
        @dragleave="OnDragLeave"
        @dragover.prevent
        @drop="onDrop"
        :class="{ dragging: isDragging }">

        <div v-show="!images.length">
            <font-awesome-icon :icon="['fas', 'cloud-upload-alt'] " />
            <p>Dateien hierhin bewegen</p>
            <div>oder</div>
            <div class="file-input">
                <label for="file">Dateien auswählen</label>
                <input type="file" id="file" @change="onInputChange" multiple>
            </div>
        </div>
        <div class="images-preview" v-show="images.length">
            <div class="img-wrapper" v-for="(image, index) in images" :key="index">
                <div class="delete-file" @click="deleteFile(index)">Löschen</div>
                <!-- <img :src="image" :alt="`Image Uploader ${index}`"> -->
                <div class="details">
                    <span class="name" v-text="truncateString(files[index].name, 50)"></span>
                    <span class="size" v-text="files[index].size/1000000 + ' Mb'"></span>
                </div>
            </div>
        </div>
        <div class="upload-control" v-show="images.length" :class="{ dragging: isDragging }">
            <label for="file">Dateien auswählen</label>
            <!-- <button @click="upload">Hochladen</button> -->
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        isDragging : false,
        dragCount: 0,
        images: [],
        files: [],
    }),
    methods: {
        truncateString(str, num) {
            if (num > str.length){
                return str;
            } else{
                str = str.substring(0,num);
                return str+"...";
            }
        },
        OnDragEnter(e) {
            e.preventDefault()

            this.dragCount++
            this.isDragging = true
        },
        OnDragLeave(e) {
            e.preventDefault();
            this.dragCount--
            if(this.dragCount <= 0){
                this.isDragging = false
            }
        },
        onInputChange(e) {
            const files = e.target.files
            Array.from(files).forEach(file => this.addImage(file))
        },
        onDrop(e){
            e.preventDefault()
            e.stopPropagation()
            this.dragCount--
            this.isDragging = false

            const files = e.dataTransfer.files
            Array.from(files).forEach(file => this.addImage(file))
        },
        addImage(file) {
            // if(!file.type.match('image.*')){
            //     console.log(`${file.name} ist kein Bild`)
            //     return;
                this.files.push(file)
                const reader = new FileReader()
                reader.onload = (e) => this.images.push(e.target.result)
                reader.readAsDataURL(file)
        },
        deleteFile(index){
            this.files.splice(index,1)
            this.images.splice(index,1)
        }
    },
}
</script>

<style lang="scss" scoped>
    .uploader{
        width: 100%;
        background: #e6f2fa;
        color: #009fe3;
        padding: 59px 15px;
        text-align: center;
        border-radius: 10px;
        border: 3px dashed #009fe3;
        font-size: 20px;
        position: relative;

        &.dragging {
            background: #009fe3;
            color: #e6f2fa;
            border: 3px dashed #e6f2fa;

            .file-input label {
                color: #009fe3;
                background: #e6f2fa;
            }
        }

        svg {
            font-size: 60px;
        }

        .file-input {
            width: 200px;
            margin: auto;
            height: 68px;
            position: relative;

            label, input {
                background: #009fe3;
                color: #e6f2fa;
                width: 100%;
                position: absolute;
                left: 0;
                top: 0;
                padding: 10px;
                border-radius: 4px;
                margin-top: 7px;
                cursor: pointer;        
            }

            input {
                opacity: 0;
                z-index: -2;
            }
        }

        .images-preview{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 15px;
            margin-top: -45px;

            .img-wrapper{
                /*width: 129px;
                background: #fff;
                display: flex;
                flex-direction: column;
                margin: 10px;
                margin-bottom: 20px;
                height: 150px;
                justify-content: space-between;
                box-shadow: 5px 16px 20px #3e3737;*/

                width: 100%;
                padding: 5px;
                background: #e5e9f2;
                display: flex;
                flex-direction: column;
                margin: 10px;
                height: 48px;
                justify-content: space-between;
                box-shadow: 5px 10px 20px #3e3737;
                border-radius: 7px;

                img {
                    max-height: 105px;
                }

                .delete-file{
                    position: absolute;
                    color: #009fe3;
                    background: #e5e9f2;
                    border-radius: 1px;
                    cursor: pointer;
                    right: 29px;
                    padding: 10px;
                    font-size: 12px;
                }

                .delete-file:hover{
                    background: #009fe3;
                    color: #fff;            }
            }

            .details {
                font-size: 12px;
                color: #000;
                display: flex;
                flex-direction: column;
                align-items: self-start;
                padding: 3px 6px;

                .name {
                    overflow: hidden;
                    height: 18px;
                    color: #009fe3;
                }
                .size {
                    color: #009fe3;
                }
            }
        }

        .upload-control{
            position: absolute;
            width: 100%;
            background: #e6f2fa;
            bottom: 0;
            left: 0;
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
            padding: 10px;
            text-align: right;

            /*button, label{
                background: #e6f2fa;
                border: 2px solid #03A9F4;
                border-radius: 3px;
                color: #009fe3;
                font-size: 15px;
                cursor: pointer;
            }*/

            label{
                background: #e6f2fa;
                border: 2px solid #03A9F4;
                border-radius: 3px;
                color: #009fe3;
                font-size: 15px;
                cursor: pointer;
            }

            /*button:hover{
                background: #009fe3;
                color: #fff;
            }*/

            label:hover{
                background: #009fe3; 
                color: #fff;
            }

            label{
                padding: 2px 5px;
                margin-right: 10px;
            }
        }
    }
</style>