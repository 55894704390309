<template>
    <div class="container info-container-dashboard">
        <h1>iStraight Zahnkorrektur – 25 % Mitarbeiterrabatt auf schönere Zähne!</h1>
        <br>
        <div class="img-infos">
            <img src="@/assets/images/schiene.8d12ba18ce878044f1a7.jpg">
        </div>
        <br>
        <p>
            iStraight Zahnkorrektur – 25 % Mitarbeiterrabatt auf schönere Zähne!
        </p>
        <br>
        <p>
            Wenn das kein Grund für ein strahlendes Lächeln ist: Alle Mitarbeiter Ihrer Praxis erhalten die Therapie mit unseren iStraight Zahnkorrekturschienen jetzt zum günstigen Freundschaftspreis. Leichte bis mittlere Zahnfehlstellungen (KIG 1–3) korrigiert die iStraight Therapie ganz nebenbei im Alltag – ohne Brackets, ohne Drähte und völlig unauffällig. Und so geht’s: Anhand einer Zahnabformung wird ein Set mit mehreren Zahnkorrekturschienen individuell für Ihr Gebiss angefertigt. Innerhalb der mehrwöchigen Behandlung tragen Sie nacheinander die transparenten Zahnschienen aus biokompatiblem Kunststoff in verschiedenen Materialstärken. Nach und nach werden die Zahnkronen allmählich in die gewünschte Richtung bewegt. Ist das gewünschte Therapieziel erreicht, wird es mit einem Retainer fixiert – für dauerhaft gerade Zähne. Sie wünschen sich schönere Zähne zum ermäßigten Preis? Dann sprechen Sie einfach Ihren Praxisbetreuer auf Ihre persönliche iStraight Therapie an.
        </p>
        <br>
        <p>
            Wir wünschen viel Freude an Ihrem neuen Lächeln!
        </p>
    </div>
</template>

<script>
export default {
    name: 'Istraight',
}
</script>