<template>
    <div class="container container-calculator">
        <br>
        <h1>Kostenvoranschlag berechnen</h1>
        <br>
        <h2>Schritt für Schritt zum KVA</h2>
        <br>
        <div style="height: 700px">
                <iframe
            :src="`https://zahnrechner.imexdental.de/?dentist=true`"
            width="100%"
            height="100%"
            frameborder="0" >
        </iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Calculator',
}
</script>
<style lang="scss">
    .container-calculator{
    color: #17243d;
    font-family: "NotoSans-Light";
        h1{
            font-size: 2.1rem;
            font-weight: 100;
        }

        h2{
            font-size: 1.74rem;
            font-weight: 100;
        }

        hr{
            color: 2px solid #8b919e !important; 
        }
    }
</style>