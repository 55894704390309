<template>
   <nav class="navbar navbar-light bg-light">
        <div class="container container-custom">
            <a class="navbar-brand logo-header" href="/">
                <img src="../assets/logo.svg" alt="imexdental logo" width="136" height="70" class="d-inline-block align-text-top">
            </a>   
            <div v-if="authenticated" class="buttons-principal-header">
                <font-awesome-icon v-on:click="clickProfile"  :icon="['fas', 'user'] " class="header-icons"/>
                <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                    <font-awesome-icon :icon="['fas', 'bars'] " class="header-icons"/>
                </button>
                <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div class="container">
                        <div class="offcanvas-header">
                            <a class="navbar-brand logo-header" href="/">
                                <img src="../assets/logo.svg" alt="imexdental logo" width="136" height="70" class="d-inline-block align-text-top">
                            </a>   
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div class="offcanvas-body">
                            <div class="container-custom">
                                <h1 class="offcanvas-title" id="offcanvasNavbarLabel">Navigation</h1>
                                <div class="row">
                                    <div class="col-sm-6 buttons-header" data-bs-dismiss="offcanvas">
                                        <router-link to="/"><p>Startseite</p></router-link>
                                    </div>
                                    <div class="col-sm-6 buttons-header" data-bs-dismiss="offcanvas">
                                        <router-link to="/abholungen/kostenvoranschlag"><p>Kostenvorschlag</p></router-link>
                                    </div>
                                    <div class="col-sm-6 buttons-header" data-bs-dismiss="offcanvas">
                                        <router-link to="/abholungen/lieferzeiten"><p>Lieferzeiten</p></router-link>
                                    </div>
                                    <div class="col-sm-6 buttons-header" data-bs-dismiss="offcanvas">
                                        <router-link to="/abholungen/abholung"><p>Abholung</p></router-link>
                                    </div>
                                    <div class="col-sm-6 buttons-header" data-bs-dismiss="offcanvas">
                                        <router-link to="/abholungen/scandaten"><p>Scandaten</p></router-link>
                                    </div>
                                    <div class="col-sm-6 buttons-header" data-bs-dismiss="offcanvas">
                                        <router-link to="/abholungen/bestellungen"><p>Bestellungen</p></router-link>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col abmelden-button" @click.prevent="signOut">
                                        <p>Abmelden</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav> 
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            authenticated: 'auth/authenticated',
        })
    },

    methods: {
        ...mapActions({
            signOutUser: 'auth/signOut'
        }),
        clickProfile() {
            this.$router.push({
                name: 'profile'
            })
        },
        signOut(){
            this.signOutUser()
            .then(() => {
                this.$toast.success(`Sie haben sich erfolgreich abgemeldet`,{
                    position: "top-right",
                    duration: 3000,
                });
                this.$router.push({
                    name: 'home'
                })
            })
        }
    } 
}

</script>
<style lang="scss">
    h1{
        font-size: 1.9rem;
        font-weight: bold;
    }
</style>