<template>
    <div class="container">
        <form @submit.prevent="searchDate">
            <div class="input-group">
                    <div class="col-sm-5 timepicker-custom">
                        <Datepicker calendarCellClassName="dp-custom-cell" hideOffsetDates  uid="date" :format="format" v-model="date_pickup_von" weekStart="1" :disabledWeekDays="[6, 0]" :enableTimePicker="false" class="dp-custom-input dp-custom-cell custom-pick" placeholder="Datum von" autoApply/>

                        <br>
                    </div>
                    <div class="col-sm-5 timepicker-custom">
                        <Datepicker calendarCellClassName="dp-custom-cell" hideOffsetDates  uid="date" :format="format" v-model="date_pickup_bis" weekStart="1" :disabledWeekDays="[6, 0]" :enableTimePicker="false" class="dp-custom-input dp-custom-cell custom-pick" placeholder="Datum bis" autoApply/>

                    </div>
                    <div class="col-sm-2 timepicker-custom">
                        <button type="submit" class="btn btn-search">
                            <font-awesome-icon :icon="['fas', 'search'] "/>
                        </button>
                    </div>
            </div>
        </form>
        <br>
        <table class="table table-sm table-custom">
            <thead>
                <tr>
                    <th scope="col">Auftrag</th>
                    <th scope="col">Abholung</th>
                    <th scope="col">Datum</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="collection in collections" :key="collection.id">
                    <td><div class="td-custom"><span>{{ collection.ordernumber }}</span></div></td>
                    <td><div class="td-custom"><span>{{ new Intl.DateTimeFormat('en', { day: '2-digit' }).format(new Date(collection.date)) }}.{{ new Intl.DateTimeFormat('en', { month: '2-digit' }).format(new Date(collection.date)) }}.{{ new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(collection.date)) }}</span></div></td>
                    <td><div class="td-custom"><span>{{ collection.timefrom }}-{{ collection.timeto }}</span></div></td>
                    <td>
                        <div v-if="( collection.status ) < 2 && new Date(new Date().getTime() + 5*60000) < new Date(collection.date)">
                            <button style="color: #1BA5EC;" @click="editOrder(collection.ordernumber)" type="button" class="btn"><font-awesome-icon :icon="['fas', 'edit'] "/></button>
                        </div>
                        <div v-else>
                            <button type="button" class="btn" disabled><font-awesome-icon :icon="['fas', 'edit'] "/></button>
                        </div>
                    </td>
                    <td>
                        <div v-if="( collection.status ) != 2 && new Date(new Date().getTime() + 5*60000) < new Date(collection.date)">
                            <div v-bind:id="collection.id+'delete'">
                                <button style="color: #EC1B2E;" @click="confirmCancelPickup(collection.ordernumber, collection.id, collection.date)" type="button" class="btn"><font-awesome-icon :icon="['fas', 'calendar-times'] "/></button>
                            </div>
                        </div>
                        <div v-else>
                            <button type="button" class="btn" disabled><font-awesome-icon :icon="['fas', 'calendar-times'] "/></button>
                        </div>
                        <div v-bind:id="collection.id+'deleteloading'" style="display: none;">
                            <div class="spinner-border spinner-border-sm text-danger" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                     </td>
                     <td>
                        <div v-if="( collection.status ) < 2 && new Date(new Date().getTime() + 5*60000) < new Date(collection.date)">
                            <div v-bind:id="collection.id+'send'">
                                <button style="color: #08821A;" @click="confirmSendDraftToGo(collection.ordernumber, collection.id, collection.date)" type="button" class="btn"><font-awesome-icon :icon="['fas', 'shipping-fast'] "/></button>
                            </div>
                        </div>
                        <div v-else>
                            <button type="button" class="btn" disabled><font-awesome-icon :icon="['fas', 'shipping-fast'] "/></button>
                        </div>
                        <div v-bind:id="collection.id+'sendloading'" style="display: none;">
                            <div class="spinner-border spinner-border-sm text-success" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div v-if="collection.status == 1" class="td-custom">
                            <div v-if="new Date(new Date().getTime() + 5*60000) < new Date(collection.date)">
                                <span style="color: #009fe3;">anstehend</span>
                            </div>
                            <div v-else>  
                                <span style="color: rgb(236, 27, 46);">abgelaufen</span>
                            </div>
                        </div>
                        <div v-if="collection.status == 2" class="td-custom">
                            <span style="color: rgb(236, 27, 46);">storniert</span>
                        </div>
                        <div v-if="collection.status == 3" class="td-custom">
                            <span style="color: rgb(8, 130, 26);">beauftragt</span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br>
        <div v-if="!isSearch">
            <VueTailwindPagination :current="currentPage" :total="total" :per-page="perPage" @page-changed="getAllSearchData($event)" />
        </div>
        <div v-if="isData">
            <VueTailwindPagination :current="currentPage" :total="total" :per-page="perPage" @page-changed="getAllData($event)" />
        </div>
    </div>
</template>
<script>
    import axios from "axios"
    import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'
    import '@ocrv/vue-tailwind-pagination/dist/style.css'
    import Datepicker from 'vue3-date-time-picker';
    import 'vue3-date-time-picker/dist/main.css'
    import { ref } from 'vue';
    import { mapGetters, mapActions } from 'vuex'
    export default {
        name: 'PickupList',
        data(){
            return {
                total: 8,
                perPage: 8,
                collections: [],
                currentPage: 1,
                isData: true,
                isSearch: true,
            }
        },
        setup() {
            const date_pickup_von = ref();
            const date_pickup_bis = ref();
            const format = (date_actual) => {
                const day = date_actual.getDate().toString().padStart(2, "0");
                const month = (date_actual.getMonth() + 1).toString().padStart(2, "0");
                const year = date_actual.getFullYear();
                return `${day}.${month}.${year}`;
            }
            return {
                date_pickup_von,
                date_pickup_bis,
                format
            }
        },
        components: {
            VueTailwindPagination,
            Datepicker
        },
        computed: {
            ...mapGetters({
                userDataVue: 'auth/user',
            })
        },
        methods: {
            validateFields(){
                if(this.date_pickup_von == null || this.date_pickup_bis == null){
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Datum muss ausgewählt werden',
                    })
                    this.currentPage = 1
                    return
                }
                if(Date.parse(this.date_pickup_von) > Date.parse(this.date_pickup_bis)){
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Datumsbereich ist falsch',
                    })
                    return   
                } 
                return true
            },
            editOrder(ordernumber){
                this.$router.replace({ name: 'pickup-edit', params: { hwb: ordernumber } })
            },
            formatDateHuman(date){
                let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
                let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
                let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
                return `${day}/${month}/${year}`
            },
            ...mapActions({
                deleteToken: 'auth/removeTokenUser',
                setConfirmed: 'go/setAfterSentSuccess',
            }),
            async searchDate(){ 
                if(this.validateFields()){
                    this.isData = false
                    this.isSearch = false
                    try {
                        await axios
                        .get(`show-collection/search?page=${this.currentPage}`, { 
                            params: {
                                from    : this.formatDateHuman(Date.parse(this.date_pickup_von)- 86400000),
                                to      : this.formatDateHuman(Date.parse(this.date_pickup_bis)),
                            }
                        })
                        .then((response) => {
                            if(response.data.data.length === 0){
                                this.$swal({
                                    icon: 'info',
                                    title: 'Info',
                                    text: 'Keine Datensätze gefunden',
                                })
                                this.currentPage = 1
                                this.isSearch = true
                            }
                            var responseData = response.data
                            this.collections =  responseData.data
                            this.perPage = responseData.per_page
                            this.total = responseData.total
                        })
                    } catch (e) {
                        if(e.response.status === 401){
                            this.$toast.error(`Ihre Sitzung ist beendet, bitte melden Sie sich erneut an`,{
                                position: "top-right",
                                duration: 7000,
                            });
                            this.deleteToken()
                                this.$router.replace({
                                    name: 'home'
                                })
                            return
                        }
                        this.$swal({
                            icon: 'error',
                            title: 'Fehler',
                            text: 'Bitte versuchen Sie es später erneut',
                        })
                    }
                }
            },
            getAllSearchData(pageNumber){
                this.currentPage = pageNumber
                this.searchDate()
            },    
            getAllData(pageNumber){
                this.currentPage = pageNumber
                this.getData()
            },      
            async getData() {
                try {
                    var response = await axios.get(`show-collection?page=${this.currentPage}`)
                    var responseData = response.data
                    this.collections =  responseData.data
                    this.perPage = responseData.per_page
                    this.total = responseData.total
                } catch (e) {
                    if(e.response.status === 401){
                        this.$toast.error(`Ihre Sitzung ist beendet, bitte melden Sie sich erneut an`,{
                            position: "top-right",
                            duration: 7000,
                        });
                        this.deleteToken()
                            this.$router.replace({
                                name: 'home'
                            })
                        return
                    }
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Bitte versuchen Sie es später erneut',
                    })
                }
            },
            confirmCancelPickup(ordernumber, id, date){
                if(new Date(new Date().getTime() + 5*60000) > new Date(date)){
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Datum abgelaufen. Die Seite wird erneut geladen',
                    })
                    setTimeout(() => {
                        this.$router.go()
                    }, 3000);
                    return   
                }
                this.$swal({
                    title: 'Sind Sie sicher?',
                    text: "Abholung stornieren",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ja',
                    cancelButtonText: 'Nein',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.cancelPickup(ordernumber, id)
                    }
                })            
            },
            async cancelPickup(ordernumber, id){
                document.getElementById(id+"delete").style.display = "none"
                document.getElementById(id+"deleteloading").style.display = "block"
                try {
                    let response = await axios
                    .get('cancel-go', { 
                        params: {
                            hwbNumber   : ordernumber,
                            email       : this.userDataVue.username,
                        }
                    })
                    this.$swal({
                        icon : 'success',
                        title : 'Erledigt',
                        text : 'Abholung wurde storniert!',
                    })
                    document.getElementById(id+"delete").style.display = "block"
                    document.getElementById(id+"deleteloading").style.display = "none"
                    this.getData()
                    return response
                } catch (e) {
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Bitte versuchen Sie es später erneut',
                    })
                    document.getElementById(id+"delete").style.display = "block"
                    document.getElementById(id+"deleteloading").style.display = "none"
                }
            },
            confirmSendDraftToGo(ordernumber, id, date){
                if(new Date(new Date().getTime() + 5*60000) > new Date(date)){
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Datum abgelaufen. Die Seite wird erneut geladen',
                    })
                    setTimeout(() => {
                        this.$router.go()
                    }, 3000);
                    return   
                }
                this.$swal({
                    title: 'Sind Sie sicher?',
                    text: "Abholung beauftragen",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Ja',
                    cancelButtonText: 'Nein',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.sendDraftToGo(ordernumber, id)
                    }
                })            
            },
            async sendDraftToGo(ordernumber, id){
                document.getElementById(id+"send").style.display = "none"
                document.getElementById(id+"sendloading").style.display = "block"
                try {
                    let response = await axios
                    .get('release-go', { 
                        params: {
                            hwbNumber   : ordernumber,
                            email       : this.userDataVue.username,
                        }
                    })
                    .then(() => {
                        //activate SET_AFTER_SENT_SUCCESS = true, to allow one time to show the page "GoSuccess"
                        this.setConfirmed()
                        this.$router.replace({ name: 'pickupgo-confirmed', params: { hwb: ordernumber } })
                    })  
                    document.getElementById(id+"send").style.display = "block"
                    document.getElementById(id+"sendloading").style.display = "none"
                    return response
                } catch (e) {
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Bitte versuchen Sie es später erneut',
                    })
                    document.getElementById(id+"send").style.display = "block"
                    document.getElementById(id+"sendloading").style.display = "none"
                }
            }
        },
        mounted() {
            this.currentPage = 1;
            this.getData()
        }
    }
</script>
<style lang="scss">
    .td-custom{
        margin-top: 6px;
    }
    .table-custom{
        text-align: center;
    }

    .timepicker-custom{
        padding: 15px;
    }

    .btn-search{
        height: 60px;
        width: 60px;
    }

    .pr-2{
        display: none ;
    }

    .w-14{
        display: none;
    }

    .pl-4{
        display: none;
    }

    .transform {
        transform: none;
    }
</style>