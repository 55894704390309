<template>
    <select id="dynamicSelect" class="form-select" aria-label="Default select example" v-model="krankenkasse_selected" @change="onChange()">
        <option selected>Krankenkasse auswählen</option>  
    </select>
</template>
<script>
import axios from "axios";
export default {
    name: 'PickupInsurance',
    data: () => ({
        krankenkasse_selected: '',
        krankenkasse: '',
    }),
    methods: {
        async callInsuranceList() {
            try {
                let response = await axios.get('get-insurance')
                return this.populateSelect(response.data)
            } catch (e) {
                this.$swal({
                    icon: 'error',
                    title: 'Fehler',
                    text: 'Krankenkassen wurden nicht gelanden. Bitte versuchen Sie es später erneut',
                })
                document.getElementById("upload_btn_id").style.display = "none"
            }
        },
        //On change will change the value.
        onChange() {
            this.krankenkasse = this.krankenkasse_selected
        },
        populateSelect(array_insurance){
            var selectList = document.getElementById("dynamicSelect")

            //Create and append the options
            for (var i = 0; i < array_insurance.length; i++) 
            {
                var option_dynamic = document.createElement("option")
                option_dynamic.value = array_insurance[i].name
                option_dynamic.text = array_insurance[i].name
                option_dynamic.id = array_insurance[i].id

                selectList.appendChild(option_dynamic)
            }
        }
    },
    mounted() {
        this.callInsuranceList()
    }
}


</script>