<template>
    <div class="container profile-container">
        <br><br>
        <h1>Benutzerkonto</h1>
        <br>
        <h4>Kontodaten</h4>
        <br>
        <PickupAdress />
        <br><br>
        <h4>Passwort zurücksetzen</h4>
        <br>
        <p>Neues Passwort eingeben und speichern. Ihr altes Passwort wird ungültig.</p>
        <br>
        <form action="#" @submit.prevent="changePassword">
            <div class="input-group mb-3 input-password">
                <div class="input-group-prepend icon-font">
                    <span class="input-group-text" id="basic-addon1">
                        <font-awesome-icon :icon="['fas', 'key'] " />
                    </span>
                </div>
                <input id="pass_input" v-model="pass_input" type="text" class="form-control" placeholder="Neues Passwort eingeben" aria-label="Password" aria-describedby="basic-addon1">
            </div>
            <div class="input-group mb-3 input-password">
                <div class="input-group-prepend icon-font">
                    <span class="input-group-text" id="basic-addon1">
                        <font-awesome-icon :icon="['fas', 'key'] " />
                    </span>
                </div>
                <input id="pass_again" v-model="pass_again" type="text" class="form-control" placeholder="Neues Passwort wiederholen" aria-label="Password" aria-describedby="basic-addon1">
            </div>
            <br>
            <div class="button-upload" v-if="isUploading">
                <button type="submit"><strong>Neues Passwort speichern</strong></button>
            </div>
            <br>
            <br>
        </form>
    </div>
</template>
<script>
    import PickupAdress from '@/components/Menu/Scandaten/PickupAdress'
    import axios from "axios"
    import { mapActions } from 'vuex'
    export default {
        name: 'Profile',
        data: () => ({
            isUploading : true,
            pass_input  : "",
            pass_again  : "",
        }),
        components: {
            PickupAdress,
        },
        methods: {
            ...mapActions({
                deleteToken: 'auth/removeTokenUser',
            }),
            validateFields(){
                if(this.pass_input === "" || this.pass_again === ""){
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Die Felder sind erforderlich',
                    })
                    return
                }

                if(this.pass_input !== this.pass_again){
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Die Felder stimmen nicht überein',
                    })
                    return
                }

                if(this.pass_input.length < 8){
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Das Passwort ist zu kurz. Mindestens 8 Zeichen',
                    })
                    return
                }
                return true
            },
            async changePassword(){
                if(this.validateFields()){
                    try {          
                        await axios
                        .post('change-password', null,  { 
                            params: {
                                pass_input  : this.pass_input,
                            }
                        })
                        .then((response) => {
                            document.getElementById("pass_input").value = ''
                            document.getElementById("pass_again").value = ''
                            this.$swal({
                                icon : 'success',
                                title : 'Erledigt',
                                text : 'Passwort wurde geändert!',
                            })
                            return response
                        })
                    } catch (e) {
                        if(e.response.status === 401){
                            this.$toast.error(`Ihre Sitzung ist beendet, bitte melden Sie sich erneut an`,{
                                position: "top-right",
                                duration: 7000,
                            });
                            this.deleteToken()
                            this.$router.replace({
                                name: 'home'
                            })
                            return
                        }
                        this.$swal({
                            icon: 'error',
                            title: 'Fehler',
                            text: 'Bitte versuchen Sie es später erneut',
                        })
                    }
                }
            }
        },
    }
</script>
<style lang="scss" scoped>
    .icon-font{
        span{
            height: 100%;
        }
    }
    .input-password{
        height: 55px;    
    }
    .button-upload{ 

        button{
            background-color: #81bb27;
            width: 100%;
            height: 3.5rem;
            color: #fff;
            border: none;
            border-radius: 0.3rem;
            outline: none;
            cursor: pointer;
            font-size: 1.2rem;
        }

        button:hover{
            background-color: #04833d;
        }
    }

    .profile-container{
        h1{
            font-size: 1.7rem;
            font-weight: bold;
        }

        h4{
            font-size: 1.2rem;
            font-weight: bold;
        }

    }
</style>