<template>
  <Header />
  <router-view />  
  <Footer />
  <br>
</template>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  @font-face {
    font-family: "NotoSans-Medium";
    src: url('~@/assets/fonts/NotoSans-unhinted/NotoSans-Medium.ttf');
  }


  @font-face {
    font-family: "NotoSans-Light";
    src: url('~@/assets/fonts/NotoSans-unhinted/NotoSans-Light.ttf');
  }

  @font-face {
    font-family: "NotoSans-Regular";
    src: url('~@/assets/fonts/NotoSans-unhinted/NotoSans-Regular.ttf');
  }

  @font-face {
    font-family: "NotoSans-Bold";
    src: url('~@/assets/fonts/NotoSans-unhinted/NotoSans-Bold.ttf');
  }

</style>


<script>
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'
  export default {
    components: {
      Header,
      Footer,
    }
  }
</script>