<template>
    <div class="container container-custom">
        <div class="container-interior-dashboard">
            <div class="row">
                <div  class="col">
                  <Questions />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Questions from '@/components/Questions/Questions'
  export default {
    components: {
      Questions,
    },
  }
</script>