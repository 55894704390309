<template>
    <div class="pickup-adress" v-if="!isLoading">
        <span>{{ praxis.praxis }}</span><br>
        <span>{{ praxis.salutation }} {{ praxis.title }} {{ praxis.firstname }} {{ praxis.lastname }}</span><br>
        <span>{{ praxis.street }}</span><br>
        <span>{{ praxis.zip }}</span> <span>{{ praxis.city }}</span>
    </div>
    <div class="pickup-adress" style="text-align: center;" v-if="isLoading">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>
<style>
    .pickup-adress{
        background: #e5e9f2;
        padding: 15px;
        color: #002d7e;
        font-family: "NotoSans-Medium";
    }
</style>

<script>
    import axios from "axios";
    import { mapGetters } from 'vuex'
    export default ({
        data() {
            return {
                praxis: {
                    praxis: '',
                    salutation: '',
                    title: '',
                    firstname: '',
                    lastname: '',
                    street: '',
                    zip: '',
                    city: '',
                },
                isLoading: true,
            }
        },
        computed: {
            ...mapGetters({
                getUserMyRefId: 'auth/user',
            }),
        },
        methods: {
            async callAdress() {
                try {
                    await axios
                    .get('get-data-user', { 
                        params: {
                            id_imported: this.getUserMyRefId.myrefid
                        }
                    })
                    .then((response) => {
                        this.isLoading              = false
                        this.praxis.praxis          = response.data.praxis
                        this.praxis.salutation      = response.data.salutation
                        this.praxis.title           = response.data.title
                        this.praxis.firstname       = response.data.firstname
                        this.praxis.lastname        = response.data.surname
                        this.praxis.street          = response.data.street
                        this.praxis.zip             = response.data.zip
                        this.praxis.city            = response.data.city
                    })
                } catch (e) {
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Praxis wurde nicht geladen. Bitte versuchen Sie es später erneut',
                    })
                    document.getElementById("upload_btn_id").style.display = "none"
                }
            },
        },
        mounted() {
            this.callAdress()
        },
    })
</script>