<template>
    <div class="container">
        <form @submit.prevent="searchDate">
            <div class="input-group">
                    <div class="col-sm-5 timepicker-custom">
                        <Datepicker calendarCellClassName="dp-custom-cell" hideOffsetDates  uid="date" :format="format" v-model="date_pickup_von" weekStart="1" :disabledWeekDays="[6, 0]" :enableTimePicker="false" class="dp-custom-input dp-custom-cell custom-pick" placeholder="Datum von" autoApply/>

                        <br>
                    </div>
                    <div class="col-sm-5 timepicker-custom">
                        <Datepicker calendarCellClassName="dp-custom-cell" hideOffsetDates  uid="date" :format="format" v-model="date_pickup_bis" weekStart="1" :disabledWeekDays="[6, 0]" :enableTimePicker="false" class="dp-custom-input dp-custom-cell custom-pick" placeholder="Datum bis" autoApply/>

                    </div>
                    <div class="col-sm-2 timepicker-custom">
                        <button type="submit" class="btn btn-search">
                            <font-awesome-icon :icon="['fas', 'search'] "/>
                        </button>
                    </div>
            </div>
        </form>
        <br>
        <table class="table table-sm table-custom">
            <thead>
                <tr>
                    <th scope="col">Versendet</th>
                    <th scope="col">Uhrzeit</th>
                    <th scope="col">Speichern</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="upload in uploads" :key="upload.id">
                    <td><div class="td-custom"><span>{{ new Intl.DateTimeFormat('en', { day: '2-digit' }).format(new Date(upload.created)) }}.{{ new Intl.DateTimeFormat('en', { month: '2-digit' }).format(new Date(upload.created)) }}.{{ new Intl.DateTimeFormat('en', { year: 'numeric' }).format(new Date(upload.created)) }}</span></div></td>
                    <td><div class="td-custom"><span>{{new Date(upload.created).toLocaleTimeString()}}</span></div></td>
                    <td><a target="_blank" :href="`https://laravelbackend.imexdental.de/api/downloadpdf?id=`+ upload.id" download="download"><button style="color: #EC1B2E;" type="button" class="btn"><font-awesome-icon :icon="['fas', 'file-pdf']" /></button></a></td>
                </tr>
            </tbody>
        </table>
        <br>
        <div v-if="!isSearch">
            <VueTailwindPagination :current="currentPage" :total="total" :per-page="perPage" @page-changed="getAllSearchData($event)" />
        </div>
        <div v-if="isData">
            <VueTailwindPagination :current="currentPage" :total="total" :per-page="perPage" @page-changed="getAllData($event)" />
        </div>
    </div>
</template>
<script>
    import axios from "axios"
    import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'
    import '@ocrv/vue-tailwind-pagination/dist/style.css'
    import Datepicker from 'vue3-date-time-picker';
    import 'vue3-date-time-picker/dist/main.css'
    import { ref } from 'vue';
    import { mapActions } from 'vuex'
    export default {
        name: 'ScanDataList',
        data(){
            return {
                total: 8,
                perPage: 8,
                uploads: [],
                currentPage: 1,
                isData: true,
                isSearch: true,
            }
        },
        setup() {
            const date_pickup_von = ref();
            const date_pickup_bis = ref();
            const format = (date_actual) => {
                const day = date_actual.getDate().toString().padStart(2, "0");
                const month = (date_actual.getMonth() + 1).toString().padStart(2, "0");
                const year = date_actual.getFullYear();
                return `${day}.${month}.${year}`;
            }
            return {
                date_pickup_von,
                date_pickup_bis,
                format
            }
        },
        components: {
            VueTailwindPagination,
            Datepicker
        },
        methods: {
            validateFields(){
                if(this.date_pickup_von == null || this.date_pickup_bis == null){
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Datum muss ausgewählt werden',
                    })
                    this.currentPage = 1
                    return
                }
                if(Date.parse(this.date_pickup_von) > Date.parse(this.date_pickup_bis)){
                    this.$swal({
                        icon: 'warning',
                        title: 'Info',
                        text: 'Datumsbereich ist falsch',
                    })
                    return   
                } 
                return true
            },
            formatDateHuman(date){
                let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
                let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
                let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
                return `${day}/${month}/${year}`
            },
            ...mapActions({
                deleteToken: 'auth/removeTokenUser',
            }),
            async searchDate(){ 
                if(this.validateFields()){
                    this.isData = false
                    this.isSearch = false
                    try {
                        await axios
                        .get(`show-uploads/search?page=${this.currentPage}`, { 
                            params: {
                                from    : this.formatDateHuman(Date.parse(this.date_pickup_von)- 86400000),
                                to      : this.formatDateHuman(Date.parse(this.date_pickup_bis)),
                            }
                        })
                        .then((response) => {
                            if(response.data.data.length === 0){
                                this.$swal({
                                    icon: 'info',
                                    title: 'Info',
                                    text: 'Keine Datensätze gefunden',
                                })
                                this.currentPage = 1
                                this.isSearch = true
                            }
                            var responseData = response.data
                            this.uploads =  responseData.data
                            this.perPage = responseData.per_page
                            this.total = responseData.total
                        })
                    } catch (e) {
                        if(e.response.status === 401){
                            this.$toast.error(`Ihre Sitzung ist beendet, bitte melden Sie sich erneut an`,{
                                position: "top-right",
                                duration: 7000,
                            });
                            this.deleteToken()
                                this.$router.replace({
                                    name: 'home'
                                })
                            return
                        }
                        this.$swal({
                            icon: 'error',
                            title: 'Fehler',
                            text: 'Bitte versuchen Sie es später erneut',
                        })
                    }
                }
            },
            getAllSearchData(pageNumber){
                this.currentPage = pageNumber
                this.searchDate()
            },    
            getAllData(pageNumber){
                this.currentPage = pageNumber
                this.getData()
            },      
            async getData() {
                try {
                    var response = await axios.get(`show-uploads?page=${this.currentPage}`)
                    var responseData = response.data
                    this.uploads =  responseData.data
                    this.perPage = responseData.per_page
                    this.total = responseData.total
                } catch (e) {
                    if(e.response.status === 401){
                        this.$toast.error(`Ihre Sitzung ist beendet, bitte melden Sie sich erneut an`,{
                            position: "top-right",
                            duration: 7000,
                        });
                        this.deleteToken()
                            this.$router.replace({
                                name: 'home'
                            })
                        return
                    }
                    this.$swal({
                        icon: 'error',
                        title: 'Fehler',
                        text: 'Bitte versuchen Sie es später erneut',
                    })
                }
            },
        },
        mounted() {
            this.currentPage = 1;
            this.getData()
        }
    }
</script>
<style lang="scss">
    .td-custom{
        margin-top: 6px;
    }
    .table-custom{
        text-align: center;
    }

    .timepicker-custom{
        padding: 15px;
    }

    .btn-search{
        height: 60px;
        width: 60px;
    }

    .pr-2{
        display: none ;
    }

    .w-14{
        display: none;
    }

    .pl-4{
        display: none;
    }

    .transform {
        transform: none;
    }
</style>