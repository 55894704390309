<template>
    <div class="container container-custom">
        <div class="container-interior-dashboard">
            <div class="row">
                <Menu_Left />
                <div  class="col-sm">
                  <Datenschutz />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import Menu_Left from '@/components/Menu_Left'
  import Datenschutz from '@/components/Footer/Datenschutz'
  export default {
    components: {
      Menu_Left,
      Datenschutz,
    },
  }
</script>