<template>
    <div class="container footer container-custom">
        <div class="footer-inside">
            <div class="row">
                <div class="col-sm-9 block-footer-upper">
                    <div class="row">
                        <div class="col-2 align-self-center">
                            <a class="footer-img-bubble" href="#">
                                <img src="../assets/images/bubble-chat-white.svg" alt="bubble logo" width="80" height="80" >
                            </a>
                        </div>
                        <div class="col-10 tel-left">
                            <div class="tel-service">
                                <!-- <p>0800 - 90808080</p> -->
                                <p><a href="tel:080090808080">0800 - 90808080</a></p>
                            </div>
                            <div class="tel-service-under">
                                <p>Kostenlose Servicerufnr. Mo.-Fr. 08:00-18:00 Uhr</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-3 align-self-center footer-infos">
                    <div class="row">
                        <div class="col"><router-link to="/datenschutz"><p>Datenschutz</p></router-link></div>
                        <div class="col"><router-link to="/agb"><p>AGB</p></router-link></div>
                        <div class="col"><router-link to="/impressum"><p>Impressum</p></router-link></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>