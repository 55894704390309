<template>
    <div class="container control-container-login container-custom" >
        <div class="row" v-if="isLoadingData">
            <div class="col">
                <div class="vue-template">
                    <form action="#" @submit.prevent="login">
                        <h1>Willkommen bei IMEX®</h1>
                        <div class="form-group">
                            <input type="email" class="form-control form-control-lg" placeholder="E-Mail-Adresse" v-model="form.username">
                        </div>
                        <br>
                        <div class="form-group">
                            <input type="password" class="form-control form-control-lg" placeholder="Passwort" v-model="form.password">
                        </div>
                        <br>
                        <button type="submit" class="btn btn-dark  btn-block">Anmelden</button>
                        <p class="forgot-password mt-2 mb-4">
                            <a class="a-tag-forgot-password"><router-link to="/password">Passwort vergessen?</router-link></a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
        <div id="loading" v-if="!isLoadingData">
            <div class='container1'>
                <div class='loader'>
                    <div class='loader--dot'></div>
                    <div class='loader--dot'></div>
                    <div class='loader--dot'></div>
                    <div class='loader--dot'></div>
                    <div class='loader--dot'></div>
                    <div class='loader--dot'></div>
                    <div class='loader--text'></div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    /* The close button */
    .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
    }

    /* When moving the mouse over the close button */
    .closebtn:hover {
    color: black;
    }
    /*ALERT END*/

    .forgot-password a{
        color: #009fe3 !important;
    }

    .form-control-lg{
        padding: 0.8rem 1rem;
    }

    h1{
        font-family: "NotoSans-Light" !important;
        font-weight: 100;
        color: #17243d;
        font-size: 2rem;
    }

    input{
        border-color: #8b919e;
        color: #5d6577 !important;
        background-color: white !important;
        font-family: "NotoSans-Regular" !important;
    }

    button:hover{
        background: #04833D;
    }

    #loading {
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: center;
        opacity: 0.7;
        background-color: #fff;
        z-index: 99;
    }
    /* ====  Preloader styles ==== */

    .container1 {
        height: 100vh;
        width: 100vw;
        font-family: Helvetica;
    }

    .loader {
        height: 20px;
        width: 250px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    .loader--dot {
        animation-name: loader;
        animation-timing-function: ease-in-out;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        height: 20px;
        width: 20px;
        border-radius: 100%;
        background-color: black;
        position: absolute;
        border: 2px solid white;
    }
    .loader--dot:first-child {
        background-color: #8cc759;
        animation-delay: 0.5s;
    }
    .loader--dot:nth-child(2) {
        background-color: #8c6daf;
        animation-delay: 0.4s;
    }
    .loader--dot:nth-child(3) {
        background-color: #ef5d74;
        animation-delay: 0.3s;
    }
    .loader--dot:nth-child(4) {
        background-color: #f9a74b;
        animation-delay: 0.2s;
    }
    .loader--dot:nth-child(5) {
        background-color: #60beeb;
        animation-delay: 0.1s;
    }
    .loader--dot:nth-child(6) {
        background-color: #fbef5a;
        animation-delay: 0s;
    }
    .loader--text {
        position: absolute;
        top: 200%;
        left: 0;
        right: 0;
        margin: auto;
        color: #080807;
    }
    .loader--text:after {
        content: "Einen Augenblick bitte";
        font-weight: bold;
        animation-name: loading-text;
        animation-duration: 7s;
        animation-iteration-count: infinite;
    }

    @keyframes loader {
        15% {
            transform: translateX(0);
        }
        45% {
            transform: translateX(230px);
        }
        65% {
            transform: translateX(230px);
        }
        95% {
            transform: translateX(0);
        }
        }
        @keyframes loading-text {
        0% {
            content: "Verbinden";
        }
        25% {
            content: "Profil aufrufen";
        }
        50% {
            content: "Abholungen laden";
        }
        75% {
            content: "Daten synchronisieren";
        }
    }
</style>
<script>
    import { mapActions } from 'vuex'
    export default {
        name: 'Home', 
        data () {
            return {
                isLoadingData: true,
                form: {
                    username: '',
                    password: '',
                }
            }
        },
        methods: {
            ...mapActions({
                signUser: 'auth/signIn'
            }),

            login(){
                this.isLoadingData = false
                this.signUser(this.form)
                .then( () => {
                    this.isLoadingData = true,
                    this.$toast.success(`Willkommen zurück`,{
                        position: "top-right",
                        duration: 2000,
                    });
                    this.$router.push('/dashboard')
                })
                .catch(() =>{
                    this.$toast.error(`Benutzername oder Passwort sind falsch`,{
                        position: "top-right",
                        duration: 4000,
                    });
                    this.isLoadingData = true                    
                })
            }
        },
    }
</script>
