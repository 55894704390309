<template>
    <center>
        <h1>ERROR 404</h1>
        <h2>Seite nicht gefunden!</h2>

    </center>
</template>

<style scoped>

  center {
    margin: 15vw;
  }

  h1 {
    color: var(--border);
  }

</style>

<script>
  export default {
    name: 'Notfound', 
  }
</script>