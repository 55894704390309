<template>
    <div class="container control-container-login container-custom" >
        <div class="row">
            <div class="col">
                <div class="vue-template">
                    <form action="#" @submit.prevent="email_recover">
                        <h1>Bitte Ihre E-Mail eingeben</h1>
                        <div class="form-group">
                            <input type="email" class="form-control form-control-lg" placeholder="E-Mail-Adresse" v-model="form.email">
                        </div>
                        <br>
                        <button type="submit" class="btn btn-dark btn-lg btn-block">Passwort wiederherstellen</button>
                        <p class="forgot-password mt-2 mb-4">
                            <a class="a-tag-forgot-password"><router-link to="/">zum Login</router-link></a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    h1{
        font-family: "NotoSans-Light" !important;
        font-weight: 100;
        color: #17243d;
        font-size: 2rem;
    }
    .forgot-password a{
        color: #009fe3 !important;
    }

    button:hover{
        background: #04833D;
    }
</style>
<script>
    import axios from "axios";
    export default {
        name: 'Home', 
        data () {
            return {
                form: {
                    email: '',
                }
            }
        },
        methods: {
            async email_recover(){
                 try {
                    await axios
                    .get('recover-password-email', { 
                        params: {
                            email   : this.form.email,
                        }
                    })
                    .then( () => {
                        this.$toast.success(`Eine E-Mail wurde an Sie gesendet`,{
                            position: "top-right",
                            duration: 2000,
                        });
                        this.$router.push('/')
                    })
                 } catch (e) {
                    this.$toast.error(`Etwas ist schief gelaufen`,{
                        position: "top-right",
                        duration: 4000,
                    });
                }
            }
        } 
    }
</script>
